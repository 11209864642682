import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Switch, FormControlLabel, Typography } from "@mui/material";

import { fetchComplex } from "../../../redux/slices/universalSlice";
import { editComplex } from "../../../redux/slices/universalSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

const neumorphicTextFieldStyle = {
  width: "100%",
  margin: "8px 0",
  padding: "10px 12px",
  background: "#e0e0e0",
  borderRadius: "12px",
  border: "none",
  boxShadow: "inset 4px 4px 8px rgba(0, 0, 0, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.5)",
  fontSize: "1rem",
  color: "#333",
  outline: "none",
  "&::placeholder": {
    color: "#aaa",
  },
};

const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditComplex({ complex_uuid, onBack }) {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const customer_uuid = useSelector((state) => state.auth.customer_uuid);

  const [formData, setFormData] = useState({
    complex_name: "",
    complex_address1: "",
    complex_address2: "",
    complex_postalcode: "",
    complex_province: "",
    complex_verified:false,
    complex_verified_message:""
  });

  const { complex } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (complex_uuid) {
      dispatch(fetchComplex({ complex_uuid }));
    }
  }, [complex_uuid, dispatch]);

  useEffect(() => {
    setFormData({
      complex_name: complex.complex_name || "",
      complex_address1: complex.complex_address1 || "",
      complex_address2: complex.complex_address2 || "",
      complex_postalcode: complex.complex_postalcode || "",
      complex_province: complex.complex_province || "",
      complex_verified:complex.complex_verified||false,
      complex_verified_message:complex.complex_verified_message||""
    });
  }, [complex]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value,
      complex_verified_message: '', }));
  };
  const handleToggle = (field) => {
    setFormData((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSubmit = async() => {
    try {
      const result = await dispatch(editComplex({ user_uuid, customer_uuid,complex_uuid, data: formData })).unwrap();
      dispatch(fetchComplex({ complex_uuid }));
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Complex</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Input label="Name" placeholder="Complex Name" name="complex_name" value={formData.complex_name} onChange={handleChange} />
          <Input label="Address Line 1" placeholder="Address Line 1" name="complex_address1" value={formData.complex_address1} onChange={handleChange} />
          <Input label="Address Line 2" placeholder="Address Line 2" name="complex_address2" value={formData.complex_address2} onChange={handleChange} />
          <Input slabel="Postal Code" placeholder="Postal Code" name="complex_postalcode" value={formData.complex_postalcode} onChange={handleChange} />
          <Input label="Province" placeholder="Province" name="complex_province" value={formData.complex_province} onChange={handleChange} />
          <FormControlLabel
            control={
              <Switch
                checked={formData.complex_verified}
                onChange={() => handleToggle("complex_verified")}
                sx={{
                  "& .MuiSwitch-switchBase.Mui-checked": {
                    color: "green",
                  },
                  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                    backgroundColor: "green",
                  },
                  "& .MuiSwitch-switchBase": {
                    color: "red",
                  },
                  "& .MuiSwitch-track": {
                    backgroundColor: formData.complex_verified ? "green" : "red",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  color: "#333",
                  fontWeight: "bold",
                }}
              >
                Complex Verified
              </Typography>
            }
          />
           <Input label="Complex Verified Message" placeholder="Complex Verified Message" name="complex_verified_message" value={formData.complex_verified_message} onChange={handleChange} />
          

           <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditComplex;
