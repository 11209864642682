import React from "react";

const Input = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  maxWidth = "100%",
  inputPadding = "10px 12px",
  marginBottom = "16px",
  labelPadding = "4px 8px",
  disabled=false
}) => {
  const neumorphicContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: marginBottom,
    maxWidth: maxWidth,
  };

  const neumorphicLabelStyle = {
    fontSize: "0.9rem",
    color: "#333",
    padding: labelPadding,
    background: "#e0e0e0",
    borderRadius: "8px",
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  };

  const neumorphicTextFieldStyle = {
    width: "100%",
    padding: inputPadding,
    background: "#e0e0e0",
    borderRadius: "12px",
    border: "none",
    boxShadow: "inset 4px 4px 8px rgba(0, 0, 0, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.5)",
    fontSize: "1rem",
    color: "#333",
    outline: "none",
    cursor: disabled ? "not-allowed" : "text", // Adjust cursor style if disabled
    opacity: disabled ? 0.6 : 1, // Reduce opacity if disabled
  };
  return (
    <div style={neumorphicContainerStyle}>
      <label style={neumorphicLabelStyle} htmlFor={name}>
        {label}
      </label>
      <input
        id={name}
        style={neumorphicTextFieldStyle}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="off"
        disabled={disabled}
        inputProps={{
          autoComplete: "new-password",
        }}
      />
    </div>
  );
};

export default Input;
