// src/pages/CustomerPage.jsx
import React from "react";
import { Typography } from "@mui/material";

function CustomerPage() {
  return (
    <div>
      <Typography variant="h4">Welcome to the Landlord Portal</Typography>
      <Typography variant="body1">
        {/* Add content specific to the customer portal */}
        Here landlord can view payouts and shows overview of everything .
      </Typography>
    </div>
  );
}

export default CustomerPage;
