import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CardTypography from "../Typography/CardTypography";

import Chip from "../misc/Chip";

const CustomerArrearsFee = ({ arrears_fee, callback_editArrearsFee }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
    maxWidth: "400px",
  };

  const statusIcon = (status) => (status ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />);

  const click_editFee = ({ arrears_uuid }) => {
    callback_editArrearsFee({ arrears_uuid });
  };

  return (
    <Box sx={neumorphicStyles}>
      <CardTypography variant="h4" sx={{ marginBottom: 2 }}>
        Customer Arrears Fee
      </CardTypography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        {statusIcon(arrears_fee.arrears_percentage)}
        <CardTypography variant="h6">Percentage Arrears</CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1, paddingLeft: 6 }}>
        <CardTypography>
          <strong>Percentage Amount:</strong> {arrears_fee.arrears_percentage_amount || ""}
        </CardTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
        {statusIcon(arrears_fee.arrears_fixed)}
        <CardTypography variant="h6">Fixed Arrears</CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1, paddingLeft: 6 }}>
        <CardTypography>
          <strong>Fixed Amount:</strong> {arrears_fee.arrears_fixed_amount || "N/A"}
        </CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1, paddingLeft: 6 }}>
        <CardTypography>
          <strong>Paid Amount:</strong> {arrears_fee.arrears_paid_amount || "N/A"}
        </CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1, paddingLeft: 6 }}>
        <CardTypography>
          <strong>Outstanding Amount:</strong> {arrears_fee.arrears_outstanding_amount || "N/A"}
        </CardTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {arrears_fee.arrears_percentage || arrears_fee.arrears_fixed ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
        <Chip label={arrears_fee.customer_arrears_fee_verified ? "Verified" : "Unverified"} verified={arrears_fee.customer_arrears_fee_verified} />
      </Box>

      <Box sx={{ marginBottom: 1, marginTop: 1, paddingLeft: 6 }}>
        <CardTypography> {arrears_fee.customer_arrears_fee_verified_message || null}</CardTypography>
      </Box>

      <Box sx={{ marginBottom: 1, marginTop: 1, paddingLeft: 6 }}>
        <CardTypography>Date Created: {arrears_fee.created_date ? new Date(arrears_fee.created_date).toLocaleDateString() : null}</CardTypography>
      </Box>

      <Button
        variant="contained"
        onClick={() => click_editFee({ arrears_uuid: arrears_fee.arrears_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        Edit
      </Button>
    </Box>
  );
};

export default CustomerArrearsFee;
