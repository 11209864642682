// src/pages/admin/customer_management/LandlordManagement.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchLandlordDetails } from "../../../redux/slices/landlordManagementSlice";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import LandlordCard from "../../../components/cards/LandlordCard";
import RepresentativeCard from "../../../components/cards/RepresentativeCard";
import CommunicationCard from "../../../components/cards/CommunicationCard";

import CardTypography from "../../../components/Typography/CardTypography";
import Chip from "../../../components/misc/Chip";
import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import ComplexManagement from "../complexManagement/ComplexManagement";

import EditLandlord from "../edit/EditLandlord";
import EditBank from "../edit/EditBank";
import EditDocuments from "../edit/EditDocuments";
import EditCommunication from "../edit/EditCommunication";
import EditRepresentative from "../edit/EditRepresentative";
import EditComplex from "../edit/EditComplex";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function LandlordManagement({ landlord, onBack }) {
  const dispatch = useDispatch();
  const customer_uuid = landlord.customer_uuid; //useSelector((state) => state.auth.customer_uuid);
  const [currentView, setCurrentView] = useState("landlordManagement");

  //API `/landlordManagement-onload`
  //*******************************************
  const {
    complexes,
    customer,
    customer_documents,
    customer_representative,
    customer_communication,
    loading,
    error, //
  } = useSelector((state) => state.landlordSlice);
  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchLandlordDetails(customer_uuid));
    }
  }, [customer_uuid,currentView, dispatch]);
  //*******************************************

  //*******************************************
  //view clicked
  const [selectedComplex, setSelectedComplex] = useState(null);
  const click__view_complex = ({ complex }) => {
    setSelectedComplex(complex);
    setCurrentView("ComplexManagement");
  };
  //*******************************************

  //*******************************************
  //edit clicked
  const click__edit_complex = ({ complex_uuid }) => {
    setCurrentView(() => <EditComplex complex_uuid={complex_uuid} onBack={() =>{dispatch(fetchLandlordDetails(customer_uuid)); setCurrentView("landlordManagement")} }/>);
  };
  const click__edit_landlord = ({ customer_uuid }) => {
    setCurrentView(() => <EditLandlord customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} heading="back to landlord management" />);
  };
  const click__edit_bank = ({ customer_uuid }) => {
    setCurrentView(() => <EditBank customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_documents = ({ customer_uuid }) => {
    setCurrentView(() => <EditDocuments customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_representative = ({ customer_uuid }) => {
    setCurrentView(() => <EditRepresentative customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  const click__edit_communication = ({ customer_uuid }) => {
    setCurrentView(() => <EditCommunication customer_uuid={customer_uuid} onBack={() => setCurrentView("landlordManagement")} />);
  };
  //*******************************************

  if (typeof currentView !== "string") {
    return currentView;
  }

  if (currentView === "ComplexManagement") {
    return <ComplexManagement complex_uuid={selectedComplex.complex_uuid} onBack={() => setCurrentView("landlordManagement")} />;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Customer Management
          </Button>
          <CardTypography variant={"h3"}>Landlord Management</CardTypography>
        </ColumnHeading>
      </Row>

      {/* Row 1 */}
      <Column xs={8} maxHeight={"800px"}>
        <Row>
          <ColumnInset xs={12} maxHeight="350px">
            {/* complex */}
            <Table maxHeight="200px">
              <TableHead>
                <TableRow>
                  <TableCell>Complex</TableCell>
                  <TableCell>Complex Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Units</TableCell>
                  <TableCell>Complex Details</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {complexes.map((row) => (
                  <TableRow key={row.complex_uuid}>
                    <TableCell>{row.complex_uuid}</TableCell>
                    <TableCell>{row.complex_name}</TableCell>
                    <TableCell>
                      {row.complex_address1},{row.complex_address2},{row.complex_postalcode},{row.complex_province}
                    </TableCell>
                    <TableCell>{row.complex_tenants}</TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip label={row.complex_verified ? "Verified" : "Unverified"} verified={row.complex_verified} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => click__view_complex({ complex: row })}>View</TableButton>
                        <TableButton onClick={() => click__edit_complex({ complex_uuid: row.complex_uuid })}>Edit</TableButton>
                      </TableButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>
        <Row>
          {/* cards */}
          <ColumnInset xs={6}>
            <RepresentativeCard customer_representative={customer_representative} callback_editrepresentative={click__edit_representative} />
          </ColumnInset>
          <ColumnOutset xs={6}>
            <CommunicationCard customer_communication={customer_communication} callback_editcommunication={click__edit_communication} />
          </ColumnOutset>


        </Row>
      </Column>

      {/* landlord card */}
      <Column xs={4}>
        <Row>
          <ColumnOutset xs={12} padding={0}>
            <LandlordCard customer={customer} customer_documents={customer_documents} callback_editlandlord={click__edit_landlord} callback_editbank={click__edit_bank} callback_editdocuments={click__edit_documents} />
          </ColumnOutset>
        </Row>
      </Column>

    </Container>
  );
}

/*
help me create a reusable table component that i can use in my react mui material app.
the table must be compact, to allow as much info and columns as possible without line breaks, so i want little to no padding in the cells, i want to be able to easily customize the borders,background color(s) and font in the table cells. the table must take as param the width , so if i give width:'100%' then it must be full width,if i give width'50%' then it must be half.
*/

export default LandlordManagement;
