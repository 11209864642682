import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography, TableBody, TableCell, TableHead, TableRow, Switch, FormControlLabel } from "@mui/material";
import { fetchDocuments, editDocuments, editDocumentVerified } from "../../../redux/slices/universalSlice";

import Input from "../../../components/fields/Input";
import CardTypography from "../../../components/Typography/CardTypography";
import UploadFile from "../../../components/buttons/UploadFile";
import Chip from "../../../components/misc/Chip";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function EditDocuments({ customer_uuid, onBack }) {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const { customer_documents } = useSelector((state) => state.universalSlice);
  const [loadingStatus, setLoadingStatus] = useState({}); // Track loading status per document

  const [formData, setFormData] = useState({
    document_verified: false,
    document_verified_message: "",
  });

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchDocuments({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  useEffect(() => {
    // Initialize row states when documents are fetched
    if (customer_documents.length > 0) {
      const initialStates = customer_documents.reduce((acc, doc) => {
        acc[doc.document_uuid] = {
          document_verified: doc.document_verified || false,
          document_verified_message: doc.document_verified_message || "",
        };
        return acc;
      }, {});
      setRowStates(initialStates);
    }
  }, [customer_documents]);

  const [rowStates, setRowStates] = useState({});
  const handleRowChange = async (document_uuid, field, value) => {

    const updatedRow = {
      ...rowStates[document_uuid],
      [field]: value,
    };

    setRowStates((prev) => ({
      ...prev,
      [document_uuid]: updatedRow,
    }));

    if (field === "document_verified") {
      try {
        await dispatch(
          editDocumentVerified({
            user_uuid,
            customer_uuid,
            document_uuid,
            document_verified: updatedRow.document_verified,
            document_verified_message: updatedRow.document_verified_message,
          })
        ).unwrap();
        console.log(`Document ${document_uuid} verified status updated successfully.`);
      } catch (error) {
        console.error(`Failed to update document ${document_uuid} verification:`, error);
        alert("Failed to update document verification status.");
      }
    }

  };

  const handleFileUpload = async (file, document_uuid, document_type) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("user_uuid", user_uuid);
    formData.append("customer_uuid", customer_uuid);
    formData.append("document_uuid", document_uuid);
    formData.append(document_type, file);
    setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));

    try {
      const resultAction = await dispatch(editDocuments({ formData })).unwrap();
      console.log("Upload successful:", resultAction);
    } catch (err) {
      console.error("Error uploading document:", err);
      alert("Failed to upload document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Documents</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={12}>
          {customer_documents.length === 0 ? (
            <Typography>No documents available.</Typography>
          ) : (
            <Table maxHeight="800px">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>MimeType</TableCell>
                  <TableCell>Document verified</TableCell>
                  <TableCell>Document verified Message</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer_documents.map((doc, index) => (
                  <TableRow key={doc.document_uuid}>
                    <TableCell>{doc.document_name}</TableCell>
                    <TableCell> {doc.document_size ? `${(doc.document_size / (1024 * 1024)).toFixed(2)} MB` : ""}</TableCell>
                    <TableCell> {doc.document_mimetype} </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {doc.document_filename ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={doc.document_verified ? "Verified" : "Unverified"} verified={doc.document_verified} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={rowStates[doc.document_uuid]?.document_verified || false}
                              onChange={(e) => handleRowChange(doc.document_uuid, "document_verified", e.target.checked)}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": {
                                  color: "green",
                                },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                  backgroundColor: "green",
                                },
                                "& .MuiSwitch-switchBase": {
                                  color: "red",
                                },
                                "& .MuiSwitch-track": {
                                  backgroundColor: formData.document_verified ? "green" : "red",
                                },
                              }}
                            />
                          }
                          label={
                            <Typography
                              sx={{
                                color: "#333",
                                fontWeight: "bold",
                              }}
                            >
                              Document Verified
                            </Typography>
                          }
                        />
                        <Input label="Document Verified Message" placeholder="Document Verified Message" name="document_verified_message" value={rowStates[doc.document_uuid]?.document_verified_message || ""} onChange={(e) => handleRowChange(doc.document_uuid, "document_verified_message", e.target.value)} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: "25px" }}>
                        <TableButton onClick={() => window.open(`${process.env.REACT_APP_API_URL}/document_uploads/${doc.document_filename}`, "_blank")}>View</TableButton>
                        <UploadFile label="Upload" tooltip="Upload a new document here" callback={(file) => handleFileUpload(file, doc.document_uuid, doc.document_type)} disabled={loadingStatus[doc.document_uuid]} disabled_tooltip="Uploading..." enabled_tooltip="Upload a new document" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            // customer_documents.map((doc) => (
            //   <Box
            //     key={doc.document_uuid}
            //     sx={{
            //       display: "flex",
            //       alignItems: "center",
            //       justifyContent: "space-between",
            //       padding: "10px",
            //       marginBottom: "10px",
            //       background: "#e0e0e0",
            //       boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
            //       borderRadius: "12px",
            //     }}
            //   >
            //     <Typography>{doc.document_type}</Typography>
            //     <Typography>{doc.document_mimetype}</Typography>
            //     <Box sx={{ display: "flex", gap: "10px" }}>
            //       <Button sx={neumorphicButtonStyle} onClick={() => window.open(`${process.env.REACT_APP_API_URL}/document_uploads/${doc.document_filename}`, "_blank")}>
            //         View
            //       </Button>
            //       <UploadFile label="Upload" tooltip="Upload a new document here" callback={(file) => handleFileUpload(file, doc.document_uuid, doc.document_type)} disabled={loadingStatus[doc.document_uuid]} disabled_tooltip="Uploading..." enabled_tooltip="Upload a new document" />
            //     </Box>
            //   </Box>
            // ))
          )}
        </ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditDocuments;
