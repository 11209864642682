import React from "react";
import { Box, Typography, Card, CardContent } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

// import backgroundImage from "../../../assets/electricity/hero3.jpg";
import landisLogo from "../../../assets/suppliers/landis.jpg";
import hexingLogo from "../../../assets/suppliers/hexing.jpg";
import conlogLogo from "../../../assets/suppliers/conlog.png";
import laisonLogo from "../../../assets/suppliers/laison.png";

const NeumorphicCard = styled(Card)(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  maxWidth: "15%",
  height: "350px",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "&:hover": {
    boxShadow: `5px 5px 15px ${theme.palette.blackShades.medium}, -5px -5px 15px ${theme.palette.blackShades.light}`,
    transform: "translateY(-5px)", // Slight lift on hover
  },
}));

const SupplierLogo = styled("img")(({ theme }) => ({
  width: "200px",
  height: "auto",
  objectFit: "cover",
  marginBottom: theme.spacing(1),
}));

function Suppliers() {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCardClick = (supplierName) => {
    navigate(`/supplier/${supplierName}`);
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 60px)",
        position: "relative",
        backgroundColor: "rgb(0, 0, 0)", // Fallback background color
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
        m: 0,
        p: 0,
      }}
    >
      {/* Overlay Content */}
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "8%",
          transform: "translateY(-50%)",
          color: "#fff",
          textAlign: "left",
          maxWidth: "40%",
        }}
      >
        <Typography variant="h2" gutterBottom>
          Our Trusted Suppliers
        </Typography>
        <Typography variant="h5">We partner with top-tier suppliers to ensure the highest quality and reliability for all our products. Our extensive network allows us to deliver exceptional value and performance to our customers.</Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "65%", // Adjust as needed
          left: "8%",
          transform: "translateY(-50%)",
          display: "flex",
          gap: 3, // Space between cards
          mt: 2,
        }}
      >
        {/* Supplier 1: Landis */}
        <NeumorphicCard onClick={() => handleCardClick("landis")} sx={{ cursor: "pointer" }}>
          <CardContent sx={{ textAlign: "center" }}>
            <SupplierLogo src={landisLogo} alt="Landis Logo" />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              We integrate Landis' high-quality meters into our custom-built smart metering systems, ensuring accurate data collection and reliable performance tailored to your needs.
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: Hexing */}
        <NeumorphicCard onClick={() => handleCardClick("hexing")} sx={{ cursor: "pointer" }}>
          <CardContent sx={{ textAlign: "center" }}>
            <SupplierLogo src={hexingLogo} alt="Hexing Logo" />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              We utilize Hexing's advanced meters in our custom-designed energy management solutions, providing seamless integration and optimal energy monitoring for your applications.
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: laison */}
        <NeumorphicCard onClick={() => handleCardClick("laison")} sx={{ cursor: "pointer" }}>
          <CardContent sx={{ textAlign: "center" }}>
            <SupplierLogo src={laisonLogo} alt="Laison Logo" />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              We incorporate Laison's innovative metering technologies into our platform, delivering cost-effective, high-precision data collection and seamless utility management for diverse applications{" "}
            </Typography>
          </CardContent>
        </NeumorphicCard>

        {/* Supplier 2: Hexing */}
        <NeumorphicCard onClick={() => handleCardClick("Conlog")} sx={{ cursor: "pointer" }}>
          <CardContent sx={{ textAlign: "center" }}>
            <SupplierLogo src={conlogLogo} alt="Conlog Logo" />

            <Typography variant="body1" sx={{ color: theme.palette.silverShades.dark }}>
              We integrate Conlog's robust prepaid and postpaid meters into our advanced energy management solutions, ensuring secure billing processes and enhanced consumer engagement{" "}
            </Typography>
          </CardContent>
        </NeumorphicCard>
      </Box>
    </Box>
  );
}

export default Suppliers;
