// src/components/specific/Complex.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__complex } from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Button, IconButton, Typography } from "@mui/material";
import Input from "../../../../components/fields/Input";
import Table from "../../../../components/table/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import Row from "../../../../components/Grid/Row";
import ColumnInset from "../../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../../components/Grid/ColumnHeading";

const neumorphicButtonStyle = {
  marginTop: 2,
  marginRight: 4,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Complex = ({ complexes, customer_uuid }) => {
  const dispatch = useDispatch();

  const [approvalMessages, setApprovalMessages] = useState({});
  const [actionStatus, setActionStatus] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({});

  const [editStatus, setEditStatus] = useState({});
  const [editedComplexes, setEditedComplexes] = useState(
    complexes.reduce((acc, complex) => {
      acc[complex.complex_uuid] = { ...complex };
      return acc;
    }, {})
  );

  const handleMessageChange = (complex_uuid, value) => {
    setApprovalMessages((prev) => ({
      ...prev,
      [complex_uuid]: value,
    }));
  };

  const handleApprove = async (complex_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "complex",
          id: complex_uuid,
          message: approvalMessages[complex_uuid] || "",
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Complex approved successfully.");
        setActionStatus((prev) => ({ ...prev, [complex_uuid]: "approved" }));
      } else {
        toast.error(resultAction.payload || "Failed to approve complex.");
      }
    } catch (err) {
      console.error(`Error approving complex ${complex_uuid}:`, err);
      toast.error("An unexpected error occurred while approving the complex.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  const handleReject = async (complex_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "complex",
          id: complex_uuid,
          message: approvalMessages[complex_uuid] || "",
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Complex rejected successfully.");
        setActionStatus((prev) => ({ ...prev, [complex_uuid]: "rejected" }));
      } else {
        toast.error(resultAction.payload || "Failed to reject complex.");
      }
    } catch (err) {
      console.error(`Error rejecting complex ${complex_uuid}:`, err);
      toast.error("An unexpected error occurred while rejecting the complex.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  const handleEdit = (complex_uuid) => {
    setEditStatus((prev) => ({ ...prev, [complex_uuid]: true }));
  };

  const handleCancelEdit = (complex_uuid) => {
    setEditStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    setEditedComplexes((prev) => ({
      ...prev,
      [complex_uuid]: { ...complexes.find((c) => c.complex_uuid === complex_uuid) },
    }));
  };

  const handleInputChange = (complex_uuid, field, value) => {
    setEditedComplexes((prev) => ({
      ...prev,
      [complex_uuid]: {
        ...prev[complex_uuid],
        [field]: value,
      },
    }));
  };

  const handleSaveEdit = async (complex_uuid) => {
    const editedData = editedComplexes[complex_uuid];

    try {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: true }));
      const resultAction = await dispatch(
        update__complex({
          complex_uuid,
          data: editedData,
        })
      );

      if (update__complex.fulfilled.match(resultAction)) {
        toast.success("Complex details updated successfully.");
        setEditStatus((prev) => ({ ...prev, [complex_uuid]: false }));
      } else {
        toast.error(resultAction.payload || "Failed to update complex details.");
      }
    } catch (err) {
      console.error(`Error updating complex ${complex_uuid}:`, err);
      toast.error("An unexpected error occurred while updating complex details.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [complex_uuid]: false }));
    }
  };

  return (
    <Row>
      <ColumnHeading xs={12}>
        <Typography variant="h5" gutterBottom>
          Complex Details
        </Typography>
      </ColumnHeading>

      {complexes.map((complex) => (
        <Row key={complex.complex_uuid} spacing={2} sx={{ flexDirection: "row", alignItems: "flex-start" }}>
          {/* Table Column */}
          <ColumnInset xs={10}>
            <Table maxHeight="400px">
              <TableBody>
                {Object.entries(complex).map(([key, value]) => {
                  if (["complex_uuid", "complex_name", "complex_address1", "complex_address2", "complex_postalcode", "complex_province", "complex_verified", "complex_verified_message", "created_date"].includes(key)) {
                    return (
                      <TableRow key={key}>
                        <TableCell>
                          <strong>{key.replace(/_/g, " ")}</strong>
                        </TableCell>
                        <TableCell>{editStatus[complex.complex_uuid] && key !== "complex_verified" ? <Input value={editedComplexes[complex.complex_uuid][key] || ""} onChange={(e) => handleInputChange(complex.complex_uuid, key, e.target.value)} maxWidth="100%" inputPadding="6px 12px" marginBottom="2px" labelPadding="0px 8px" disabled={key.endsWith("_uuid")} /> : key === "complex_verified" ? value ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} /> : value}</TableCell>
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          </ColumnInset>

          {/* Buttons and Message Column */}
          <ColumnInset xs={2} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Input placeholder="Verification message" value={approvalMessages[complex.complex_uuid] || ""} onChange={(e) => handleMessageChange(complex.complex_uuid, e.target.value)} />
            {editStatus[complex.complex_uuid] ? (
              <>
                <Button sx={neumorphicButtonStyle} onClick={() => handleSaveEdit(complex.complex_uuid)} startIcon={<SaveIcon />}>
                  Save
                </Button>
                <Button sx={neumorphicButtonStyle} onClick={() => handleCancelEdit(complex.complex_uuid)}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button sx={neumorphicButtonStyle} onClick={() => handleApprove(complex.complex_uuid)}>
                  Approve
                </Button>
                <Button sx={neumorphicButtonStyle} onClick={() => handleReject(complex.complex_uuid)}>
                  Reject
                </Button>
                <IconButton onClick={() => handleEdit(complex.complex_uuid)} aria-label="edit">
                  <EditIcon />
                </IconButton>
              </>
            )}
          </ColumnInset>
        </Row>
      ))}
    </Row>
  );
};

export default Complex;
