// src/components/Grid/ColumnOutset.jsx
import React from "react";
import { Grid, Box } from "@mui/material";

const ColumnOutset = ({ xs, maxHeight = null, padding = 2, paddingBottom = 3, alignItems = "flex-start",mr = 0, children }) => {
  return (
    <Grid
      item
      xs={xs}
      sx={{
        backgroundColor: "#e0e0e0",
        boxShadow: "8px 8px 16px #bebebe, -8px -8px 16px #ffffff",
        borderRadius: "12px",
        padding: padding,
        display: "flex",
        alignItems,
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          textAlign: alignItems === "center" ? "center" : "inherit", // Apply text alignment for centering
          display: alignItems === "center" ? "flex" : "block", // Flexbox only when centering
          flexDirection: "column", // Needed for vertical alignment in flex mode
          justifyContent: "center", // Center vertically in flex mode
          alignItems, // Center horizontally in flex mode

          width: "100%",
          padding: padding,
          paddingBottom: paddingBottom,
          overflowY: maxHeight ? "auto" : "visible", //
          maxHeight: maxHeight || "none", //
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default ColumnOutset;
