// src/pages/services/Recharge/Recharge.jsx

import React, { useState } from "react";
import { Box, Typography, Grid, FormControlLabel } from "@mui/material";
import { styled, useTheme, useMediaQuery } from "@mui/material";
import CustomTextField from "../../../../components/fields/CustomTextField";
import CustomCheckbox from "../../../../components/fields/CustomCheckbox";

import { keyframes } from "@emotion/react";

import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import GenericEnhancedTitle from "../../../../components/custom/PageTitle";
import CustomButton from "../../../../components/fields/CustomButton";
import backgroundImageForm from "../../../../assets/images/lightbulbs1.jpg";
import Svg1 from "../../../../assets/svg/recharge1.svg";
import Svg2 from "../../../../assets/svg/recharge2.svg";
import { Helmet } from "react-helmet";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Neumorphic Form Styling
const NeumorphicForm = styled("form")(({ theme }) => ({
  backgroundImage: `linear-gradient(rgba(25, 24, 24, 0.6), rgba(21, 20, 20, 0.6)), url(${backgroundImageForm})`,
  backdropFilter: "blur(5px)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  borderTop: `1px inset white`,
  color: theme.palette.silverShades.light,

  marginTop: theme.spacing(4),
  borderRadius: "15px",
  padding: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),

  animation: `${fadeIn} 0.8s ease forwards`,
  opacity: 0, // Initial state for animation
  transform: "translateY(20px)", // Initial state for animation
  //boxShadow: neumorphicShadow(theme),
  // Optional: Add a slight backdrop filter for better readability
}));

function Recharge() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // State to handle which form is displayed
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showMeterForm, setShowMeterForm] = useState(false);

  const handleRegisterCheckboxChange = (event) => {
    setShowRegisterForm(event.target.checked);
    // Uncheck the other checkbox if this one is checked
    if (event.target.checked) {
      setShowMeterForm(false);
    }
  };

  const handleMeterCheckboxChange = (event) => {
    setShowMeterForm(event.target.checked);
    // Uncheck the other checkbox if this one is checked
    if (event.target.checked) {
      setShowRegisterForm(false);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgb(0, 0, 0)", // Fallback background color
        backgroundImage: "linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(20, 20, 20, 1) 35%, rgba(41, 41, 41, 1) 100%)",
        overflow: "hidden",
        display: "flex", // Enable Flexbox
        flexDirection: "column", // Stack children vertically
        alignItems: "center", // Center children horizontally
        justifyContent: "flex-start", // Start from the top vertically
      }}
    >
      <Helmet>
        <title>Recharge - Meterminds</title>
        <meta name="description" content="Simplify utility payments with Meter Minds' seamless recharge solutions for tenants and landlords." />
        <meta name="keywords" content="utility recharge, tenant payments, landlord utilities, seamless payment solutions" />
      </Helmet>
      {/* Enhanced Global Title */}
      <GenericEnhancedTitle icon={BoltOutlinedIcon} tooltip="Powered by Meter Minds" title="Recharge Your Account" />

      {/* <CustomPageTitle
        icon={BoltOutlinedIcon}
        tooltip="Powered by Meter Minds"
        title="Recharge Your Account"
        sx={{
          textAlign: "center",
        }}
      /> */}

      {/* Checkboxes */}
      <Box sx={{ width: isSmallScreen ? "90%" : "50%", position: "relative", left: "-20%" }}>
        <FormControlLabel
          control={<CustomCheckbox checked={showRegisterForm} onChange={handleRegisterCheckboxChange} color="primary" />}
          label={
            <Typography variant="h5" sx={{ color: theme.palette.silverShades.light }}>
              Not already one of our satisfied customers - register here
            </Typography>
          }
        />
      </Box>

      <Box sx={{ width: isSmallScreen ? "90%" : "50%", position: "relative", left: "-20%" }}>
        <FormControlLabel
          control={<CustomCheckbox checked={showMeterForm} onChange={handleMeterCheckboxChange} color="primary" />}
          label={
            <Typography variant="h5" sx={{ color: theme.palette.silverShades.light }}>
              Already a Meter Minds customer - simply enter your meter number below
            </Typography>
          }
        />
      </Box>

      {/* Registration Form */}
      {showRegisterForm && (
        <Box sx={{ width: isSmallScreen ? "90%" : "45%" }}>
          <NeumorphicForm>
            <Grid container spacing={2}>
              {/* First Name and Surname */}
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="First Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Surname" variant="outlined" />
              </Grid>

              {/* Email and Cell Number */}
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Email" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Cell Nr" variant="outlined" />
              </Grid>

              {/* Meter Number and Utility */}
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Meter No" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField fullWidth label="Utility" variant="outlined" />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                {/* <CustomSubmit type="submit">Submit</CustomSubmit> */}
                <CustomButton link="/services/recharge" text="Submit" />
              </Grid>
            </Grid>
          </NeumorphicForm>
        </Box>
      )}

      {/* Meter Number Form */}
      {showMeterForm && (
        <Box sx={{ width: isSmallScreen ? "90%" : "35%" }}>
          <NeumorphicForm>
            <Grid container spacing={2}>
              {/* Meter Number */}
              <Grid item xs={12}>
                <CustomTextField fullWidth label="Meter No" variant="outlined" />
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <CustomButton link="/services/recharge" text="Submit" />
              </Grid>
            </Grid>
          </NeumorphicForm>
        </Box>
      )}

      <Box
        component="img"
        src={Svg1}
        alt="SVG 1"
        sx={{
          position: "fixed",
          bottom: "40px",
          left: "20px",
          width: "100px",
          height: "100px",
          zIndex: 0,
        }}
      />
      <Box
        component="img"
        src={Svg2}
        alt="SVG 2"
        sx={{
          position: "fixed",
          bottom: "40px",
          right: "20px",
          width: "100px",
          height: "100px",
          zIndex: 0,
        }}
      />
    </Box>
  );
}

export default Recharge;
