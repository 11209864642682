// src/components/Grid/ColumnHeading.jsx
import React from "react";
import { Grid, Box } from "@mui/material";

const ColumnHeading = ({ xs,padding = 3, paddingBottom = 0, children }) => {
  return (
    <Grid
      item
      xs={xs}
      sx={{
        backgroundColor: "#e0e0e0",
        boxShadow: "10px 10px 20px #bebebe, -10px -10px 20px #ffffff", // Softer, larger neumorphic shadow
        borderRadius: "16px", // Larger rounded corners
        padding: padding, // More padding for emphasis
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold", // Bold text for headings
        fontSize: "1.5rem", // Slightly larger font size
      }}
    >
      <Box 
      sx={{paddingBottom}}
      >{children}</Box>
    </Grid>
  );
};

export default ColumnHeading;
