import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography, Switch, FormControlLabel } from "@mui/material";

import { fetchCommunication,editCommunication } from "../../../redux/slices/universalSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

const neumorphicButtonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditCommunication({ customer_uuid, onBack }) {
  const dispatch = useDispatch();
  const [communication_uuid, setCommunication_uuid] = useState(null);

  const [formData, setFormData] = useState({
    communication_landlord: false,
    communication_representative: false,
  });

  const {customer_communication} = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchCommunication({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  useEffect(() => {
    console.log({customer_communication})
    setCommunication_uuid(customer_communication.communication_uuid);
    setFormData({
      communication_landlord: customer_communication.communication_landlord || false,
      communication_representative: customer_communication.communication_representative || false,
    });
  }, [customer_communication]);

  const handleToggle = (field) => {
    setFormData((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const handleSubmit = async() => {
    try {
      const result = await dispatch(editCommunication({ customer_uuid,communication_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
          Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Communication</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.communication_landlord}
                  onChange={() => handleToggle("communication_landlord")}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "green",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "green",
                    },
                    "& .MuiSwitch-switchBase": {
                      color: "red",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: formData.communication_landlord ? "green" : "red",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  Communication Landlord
                </Typography>
              }
            />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.communication_representative}
                  onChange={() => handleToggle("communication_representative")}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "green",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "green",
                    },
                    "& .MuiSwitch-switchBase": {
                      color: "red",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: formData.communication_representative ? "green" : "red",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  Communication Representative
                </Typography>
              }
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditCommunication;
