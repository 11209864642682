import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CardTypography from "../Typography/CardTypography";
import Chip from "../misc/Chip";
import IconButton from "../buttons/IconButton";
import AddIcon from "@mui/icons-material/Add";

const RepresentativeCard = ({ customer_representative, callback_editrepresentative }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
  };

  const click_editrepresentative = ({ customer_uuid }) => {
    callback_editrepresentative({ customer_uuid });
  };

  return (
    <>
      {customer_representative && (
        <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
          {/* Landlord Details */}
          <CardTypography variant="h4">Representative Details</CardTypography>
          <Divider sx={{ marginY: 1 }} />
          <Box>
            <CardTypography>
              Name: {customer_representative.representative_name || null} {customer_representative.representative_sname}
            </CardTypography>
            <CardTypography>Cell: {customer_representative.representative_cell || null}</CardTypography>
            <CardTypography>Email: {customer_representative.representative_email || null}</CardTypography>
            <CardTypography>ID: {customer_representative.representative_id || null}</CardTypography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {customer_representative.representative_name ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
              <Chip label={customer_representative.representative_verified ? "Verified" : "Unverified"} verified={customer_representative.representative_verified} />
            </Box>
          </Box>
          <Button
            onClick={() => click_editrepresentative({ customer_uuid: customer_representative.customer_uuid })}
            sx={{
              marginTop: 2,
              background: "#e0e0e0",
              color: "#333",
              boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
              "&:hover": {
                background: "#f0f0f0",
                boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
              },
            }}
            variant="contained"
          >
            Edit
          </Button>
        </Box>
      )}

      {!customer_representative && (
        <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
          <CardTypography variant="h4">No Representative </CardTypography>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <IconButton icon={<AddIcon />}
             tooltip="Add Representative"
              callback={() => console.log("Add Representative Clicked")} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default RepresentativeCard;
