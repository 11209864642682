//redux/slices/universalSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchCustomer = createAsyncThunk("universal/fetchCustomer", async ({ customer_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-customer`, { customer_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchCommunication = createAsyncThunk("universal/fetchCommunication", async ({ customer_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-communication`, { customer_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchComplex = createAsyncThunk("universal/fetchComplex", async ({ complex_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-complex`, { complex_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchDocuments = createAsyncThunk("universal/fetchDocuments", async ({ customer_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-documents`, { customer_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchRepresentative = createAsyncThunk("universal/fetchRepresentative", async ({ customer_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-representative`, { customer_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchTenant = createAsyncThunk("universal/fetchTenant", async ({ tenant_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-tenant`, { tenant_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchMeter = createAsyncThunk("universal/fetchMeter", async ({ meter_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-meter`, { meter_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const fetchListMeter = createAsyncThunk("universal/fetchListMeter", async ({  }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/get-list-meter`, {  });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const editCustomer = createAsyncThunk("universal/editCustomer", async ({ user_uuid = null, customer_uuid = null, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-customer`, { user_uuid, customer_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const editBank = createAsyncThunk("universal/editBank", async ({ user_uuid = null, customer_uuid = null, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-bank`, { user_uuid, customer_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const editCommunication = createAsyncThunk("universal/editCommunication", async ({ user_uuid = null, customer_uuid, communication_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-communication`, { user_uuid, customer_uuid, communication_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const editComplex = createAsyncThunk("universal/editComplex", async ({ user_uuid = null, customer_uuid, complex_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-complex`, { user_uuid, customer_uuid, complex_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});
export const editDocuments = createAsyncThunk(
  "universal/editDocuments", //
  async ({ formData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const editDocumentVerified = createAsyncThunk(
  "universal/editDocumentVerified", //
  async ({ user_uuid, customer_uuid, document_uuid, document_verified, document_verified_message }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/universal/edit-document_verified`, //
        { user_uuid, customer_uuid, document_uuid, document_verified, document_verified_message }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch details");
    }
  }
);
export const editRepresentative = createAsyncThunk("universal/editRepresentative", async ({ user_uuid = null, customer_uuid, representative_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/universal/edit-representative`, //
      { user_uuid, customer_uuid, representative_uuid, data }
    );
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const editTenant = createAsyncThunk("universal/editTenant", async ({ user_uuid = null, customer_uuid, tenant_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-tenant`, { user_uuid, customer_uuid, tenant_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

export const editMeter = createAsyncThunk("universal/editMeter", async ({ user_uuid = null, customer_uuid, meter_uuid, tenant_uuid, data }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/universal/edit-meter`, { user_uuid, customer_uuid, meter_uuid, tenant_uuid, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

const universalSlice = createSlice({
  name: "universal",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload.customer;
        console.log(action.payload);
      })
      .addCase(fetchCustomer.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchCommunication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCommunication.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_communication = action.payload.customer_communication;
        console.log(action.payload);
      })
      .addCase(fetchCommunication.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchComplex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.complex = action.payload.complex;
        console.log(action.payload);
      })
      .addCase(fetchComplex.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_documents = action.payload.customer_documents;
        console.log(action.payload);
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchRepresentative.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRepresentative.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_representative = action.payload.customer_representative;
        console.log(action.payload);
      })
      .addCase(fetchRepresentative.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenant = action.payload.tenant;
        console.log(action.payload);
      })
      .addCase(fetchTenant.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(fetchMeter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMeter.fulfilled, (state, action) => {
        state.loading = false;
        state.meter = action.payload.meter;
        state.processed__list_meter=action.payload.processed__list_meter;
        console.log(action.payload);
      })
      .addCase(fetchMeter.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

      builder
      .addCase(fetchListMeter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchListMeter.fulfilled, (state, action) => {
        state.loading = false;
        state.processed__list_meter=action.payload.processed__list_meter;
        console.log(action.payload);
      })
      .addCase(fetchListMeter.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload.customer;
        toast.success("Customer updated!");
      })
      .addCase(editCustomer.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editBank.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editBank.fulfilled, (state, action) => {
        state.loading = false;
        state.customer = action.payload.customer;
        toast.success("Bank updated!");
      })
      .addCase(editBank.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editCommunication.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editCommunication.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_communication = action.payload.customer_communication;
        toast.success("Communication updated!");
      })
      .addCase(editCommunication.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editComplex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.complex = action.payload.complex;
        toast.success("Complex updated!");
      })
      .addCase(editComplex.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_documents = action.payload.customer_documents;
        toast.success("Customer Documents updated!");
      })
      .addCase(editDocuments.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editDocumentVerified.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editDocumentVerified.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_documents = action.payload.customer_documents;
        toast.success("Customer Document Verified updated!");
      })
      .addCase(editDocumentVerified.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editRepresentative.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editRepresentative.fulfilled, (state, action) => {
        state.loading = false;
        state.customer_representative = action.payload.customer_representative;
        toast.success("Customer Representative updated!");
      })
      .addCase(editRepresentative.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editMeter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editMeter.fulfilled, (state, action) => {
        state.loading = false;
        state.meter = action.payload.meter;
        toast.success("Meter updated!");
      })
      .addCase(editMeter.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });

    builder
      .addCase(editTenant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editTenant.fulfilled, (state, action) => {
        state.loading = false;
        state.tenant = action.payload.tenant;
        toast.success("Tenant updated!");
      })
      .addCase(editTenant.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default universalSlice.reducer;
