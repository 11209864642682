// src/pages/admin/reports/AdminReports.jsx
import React from 'react';
import { Typography } from '@mui/material';
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

function AdminReports() {
  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Typography variant={"h3"}>Report Management</Typography>
        </ColumnHeading>
      </Row>

      {/* Row 1 */}
      <Row>
        <ColumnInset xs={6}>
          <h3>Row 1, Column 1</h3>
          <p>Inset shadow column</p>
        </ColumnInset>
        <ColumnOutset xs={6}>
          <h3>Row 1, Column 2</h3>
          <p>Outset shadow column</p>
        </ColumnOutset>
      </Row>

      {/* Row 2 */}
      <Row>
        <ColumnOutset xs={4}>
          <h3>Row 2, Column 1</h3>
          <p>Outset shadow column</p>
        </ColumnOutset>
        <ColumnInset xs={8}>
          <h3>Row 2, Column 2</h3>
          <p>Inset shadow column</p>
        </ColumnInset>
      </Row>
    </Container>
  );
}

export default AdminReports;
