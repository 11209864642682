import React from "react";
import { TableContainer, Table as MuiTable, Paper } from "@mui/material";

const Table = ({ maxHeight="400px",children }) => {
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        width: "100%",
        background: "#e3e3e3", // Slightly darker shade for contrast
        borderRadius: "12px",
        boxShadow: "2px 2px 15px #b8b8b8, -2px -2px 15px #ffffff", // Enhanced outer shadow
        overflow: "auto", // Enables scrolling for content
        maxHeight // Adjust based on your layout
      }}
    >
      <MuiTable
        size="small"
        stickyHeader
        sx={{
          width: "100%", // Ensures the table itself spans the container
          "& .MuiTableCell-root": {
            padding: "6px 8px", // Minimal padding
            fontSize: "0.85rem",
            whiteSpace: "nowrap",
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            fontWeight: "bold",
            backgroundColor: "#dcdcdc", // Slight header contrast
            boxShadow: "inset 2px 2px 5px #b8b8b8, inset -2px -2px 5px #ffffff",
            textTransform: "uppercase",
          },
          "& .MuiTableBody-root .MuiTableRow-root": {
            backgroundColor: "#f0f0f0", // Slight contrast for rows
            boxShadow: "inset 1px 1px 3px #c8c8c8, inset -1px -1px 3px #ffffff",
            "&:hover": {
              backgroundColor: "#e5e5e5", // Highlight row on hover
              transition: "background-color 0.3s ease-in-out",
            },
          },
          "& .MuiTableCell-root:not(:last-child)": {
            borderRight: "1px solid #e0e0e0", // Subtle borders for cells
          },
        }}
      >
        {children}
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
