// src/components/admin/SideNavBar.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import NavButton from "./NavButton";
import { useDispatch } from "react-redux";
import { logout } from "../../../redux/slices/authSlice";

const SideNavContainer = styled(Box)(({ theme }) => ({
  width: "150px",
  height: "100vh",
  backgroundColor: theme.palette.blackShades.medium,
  boxShadow: `2px 0 5px rgba(0,0,0,0.1)`,
  position: "fixed",
  top: 0,
  left: 0,
  padding: theme.spacing(4, 2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
}));

function SideNavBar() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const navLinks = [
    { label: "Home", to: "/admin" },
    { label: "Customers", to: "/admin/customer-management" },
    { label: "Change Log", to: "/admin/change-log-management" },
    { label: "Tariffs", to: "/admin/tariff-management" },
    { label: "Website", to: "/admin/website-management" },
    { label: "Reports", to: "/admin/reports" },
  ];

  return (
    <SideNavContainer>
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.bronzeShades.bronze1,
          marginBottom: theme.spacing(4),
        }}
      >
        Admin Panel
      </Typography>
      {navLinks.map((link) => (
        <NavButton key={link.to} to={link.to} isActive={location.pathname === link.to} sx={{ marginBottom: theme.spacing(4) }}>
          {link.label}
        </NavButton>
      ))}
      <NavButton
        onClick={handleLogout}
        variant="logout"
        sx={{ marginTop: "auto", width: "100%" }} // Ensure full width
      >
        Logout
      </NavButton>
    </SideNavContainer>
  );
}

export default SideNavBar;
