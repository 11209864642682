// src/pages/customer/landlord_management/view/TenantManagement.jsx
import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import TenantCard from "../../../components/cards/TenantCard";

import SearchSelect from "../../../components/filtering/SearchSelect";
import FilterContainer from "../../../components/filtering/FilterContainer";

import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CardTypography from "../../../components/Typography/CardTypography";

import EditTenant from "../edit/EditTenant";

import { useSelector, useDispatch } from "react-redux";
import { fetchTenantManagement } from "../../../redux/slices/tenantManagementSlice";

function TenantManagement({ tenant_uuid, complex, onBack }) {
  const dispatch = useDispatch();
  // const customer_uuid = useSelector((state) => state.auth.customer_uuid);
  const [currentView, setCurrentView] = useState("TenantManagement");
  //API `/landlordManagement-onload`
  //*******************************************
  const {
    transactions,
    tenant,
    meter,
    loading,
    error, //
  } = useSelector((state) => state.tenantSlice);
  useEffect(() => {
    if (tenant_uuid) {
      dispatch(fetchTenantManagement({ tenant_uuid, complex }));
    }
  }, [tenant_uuid,currentView, dispatch]);
  //*******************************************



  const handleDropdownFilter = (selectedKey) => {
    console.log("Dropdown filter triggered:", selectedKey);
    // Perform filtering or API call based on selectedKey
  };
  const handleSearchFilter = (inputValue) => {
    console.log("Search filter triggered:", inputValue);
    // Perform search or API call based on inputValue
  };

  //*******************************************
  //add tenant clicked
  const click__capture_Tenant = () => {
    setCurrentView("CaptureTenant");
  };
  //*******************************************

  //upload tenants csv:
  const [UploadFile_tenants, set__UploadFile_tenants] = useState(true);
  const click__UploadFile_tenants = (file) => {
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };
  const click__downloadFile_tenants = (file) => {
    set__UploadFile_tenants(false);
    console.log("Uploaded file:", file);
    // Add your file processing logic here
  };

  //*******************************************
  //view clicked
  const [selectedTenant, setSelectedTenant] = useState(null);
  const click__view_Tenant = ({ tenant }) => {
    setSelectedTenant(tenant);
    setCurrentView("viewTenant");
  };
  //*******************************************

  //edit clicked
  const click__edit_tenant = ({ tenant_uuid }) => {
    setCurrentView(() => <EditTenant tenant_uuid={tenant_uuid} onBack={() => setCurrentView("TenantManagement")} heading={"Back to Tenant Management"} />);
  };

  //*******************************************
  //   if (currentView === "CaptureTenant") {
  //     return <CaptureTenant complex={complex} onBack={() => setCurrentView("TenantManagement")} />;
  //   }

  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Complex Management
          </Button>
          <CardTypography variant={"h3"}>Tenant Management</CardTypography>
        </ColumnHeading>
      </Row>

      {/* filters */}
      <Column xs={9} maxHeight={"800px"}>
        <Row spacing={0}>
          <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Account"
              data={[
                { key: "Tenant1", value: "8528521" },
                { key: "Tenant2", value: "18252" },
                { key: "Tenant3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by terminal"
              data={[
                { key: "Meter1", value: "8528521" },
                { key: "cMeter2", value: "18252" },
                { key: "Meter3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>

          <FilterContainer xs={4}>
            <SearchSelect
              type="dropdown"
              label="Filter by Date"
              data={[
                { key: "Date1", value: "8528521" },
                { key: "Date2", value: "18252" },
                { key: "Date3", value: "963287413" },
              ]}
              onFilter={handleDropdownFilter}
            />
            <SearchSelect
              type="search"
              label="Search"
              data={null} // Not required for search
              onFilter={handleSearchFilter}
            />
          </FilterContainer>
        </Row>

        {/* table */}
        <Row>
          <ColumnInset xs={12} maxHeight="400px">
            <Table maxHeight="400px">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Account Nr</TableCell>
                  <TableCell>Meter Nr</TableCell>

                  <TableCell>Transaction Nr</TableCell>
                  <TableCell>Terminal</TableCell>

                  <TableCell>Sales Amount R</TableCell>
                  <TableCell>Sales VAT R</TableCell>

                  <TableCell>Units</TableCell>
                  <TableCell>Fixed Charge</TableCell>

                  <TableCell>Arrears R</TableCell>

                  <TableCell>STS token</TableCell>

                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions &&
                  transactions.map((row, index) => (
                    <TableRow key={row.transaction_uuid}>
                      <TableCell>{row.transaction_date}</TableCell>
                      <TableCell>{row.transaction_time}</TableCell>
                      <TableCell>{row.transaction_account_number}</TableCell>
                      <TableCell>{row.transaction_meter_number}</TableCell>

                      <TableCell>{row.transaction_transaction_number}</TableCell>
                      <TableCell>{row.transaction_terminal}</TableCell>

                      <TableCell>{row.transaction_sale_amount}</TableCell>
                      <TableCell>{row.transaction_sale_vat}</TableCell>

                      <TableCell>{row.transaction_units}</TableCell>
                      <TableCell>{row.transaction_fbe_units}</TableCell>

                      <TableCell>{row.transaction_arrears_amount}</TableCell>

                      <TableCell>{row.transaction_sts_token}</TableCell>

                      <TableCell>
                        <TableButtonGroup>
                          <TableButton onClick={() => click__view_Tenant({ tenant: row })}>View</TableButton>
                        </TableButtonGroup>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>

        {/* <Row spacing={0.5}>
          <ColumnInset xs={3} alignItems="center" paddingBottom={0} padding={0} mr={2}>
            <CardTypography variant={"h5"}>Add Tenant</CardTypography>
          </ColumnInset>
          <ColumnInset xs={3} alignItems="center" paddingBottom={0} padding={0}>
            <IconButton icon={<AddIcon />} tooltip="Add a new tenant" callback={click__capture_Tenant} />
          </ColumnInset>
        </Row> */}
      </Column>

      {/* landlord card */}
      <Column xs={3}>
        <Row>
          <ColumnOutset xs={12} padding={0}>
            <TenantCard tenant={tenant} meter={meter} callback_edittenant={click__edit_tenant} />
          </ColumnOutset>
        </Row>
      </Column>

      <HorizontalDivider width="100%" margin={58} />

      {/* <Row>
        <ColumnHeading xs={12} paddingBottom={3}>
          <CardTypography variant={"h4"}>Import Tenants</CardTypography>
        </ColumnHeading>
      </Row> */}

      {/* <Row>
        <ColumnOutset xs={6}>
        </ColumnOutset>
        <ColumnInset xs={6}>
          <UploadFile
            label={"Upload Tenants"}
            tooltip={"Download the tenants template and upload here"}
            callback={click__UploadFile_tenants}
            disabled={UploadFile_tenants}
            disabled_tooltip={"Uploading is disabled untill you download the tenants template."}
            enabled_tooltip={"Populate the Template file and upload here"} //
          />
        </ColumnInset>
      </Row> */}
    </Container>
  );
}

export default TenantManagement;
