import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import CardTypography from "../../../components/Typography/CardTypography";
import MeterForm from "../../../components/forms/MeterForm";

import { useSelector, useDispatch } from "react-redux";
import { captureMeter } from "../../../redux/slices/complexManagementSlice";
import { fetchListMeter } from "../../../redux/slices/universalSlice";

function CaptureMeter({ tenant, tenants, onBack }) {
  const dispatch = useDispatch();
  const [selectedTenant, setSelectedTenant] = useState(tenant ? tenant.tenant_uuid : "");

  const { processed__list_meter } = useSelector((state) => state.universalSlice);
  useEffect(() => {
    dispatch(fetchListMeter({}));
  }, [tenants, tenant, dispatch]);

  const click__submit_meter = (formData) => {
    if (!selectedTenant) {
      alert("Please select a tenant.");
      return;
    }

    dispatch(captureMeter({ meter: formData, tenant: tenants.find((t) => t.tenant_uuid === selectedTenant) }))
      .unwrap()
      .then(() => {
        onBack(); // Navigate back on success
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const click__cancel_meter = () => {
    console.log("Cancel clicked");
    onBack();
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Management
          </Button>
          <CardTypography variant={"h3"}>Capture Meter</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnInset xs={12} alignItems="center">
          <MeterForm tenant={tenant} tenants={tenants} selectedTenant={selectedTenant} setSelectedTenant={setSelectedTenant} processed__list_meter={processed__list_meter} onSubmit={click__submit_meter} onCancel={click__cancel_meter} />
        </ColumnInset>
      </Row>
    </Container>
  );
}

export default CaptureMeter;
