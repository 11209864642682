//redux/slices/landlord_login/tenantSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; // Import createSlice and createAsyncThunk
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchTenantManagement = createAsyncThunk("tenantManagementSlice/fetchTenantManagement", //
  async ({ tenant_uuid, complex }, { rejectWithValue }) => {//
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/tenantManagement/tenantManagement-onload`, { tenant_uuid, complex });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});



const tenantManagementSlice = createSlice({
  name: "tenant",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTenantManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.meter = action.payload.meter;
        state.tenant = action.payload.tenant;
        state.transactions = action.payload.transactions;
        console.log(action.payload);
      })
      .addCase(fetchTenantManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });


  },
});

export default tenantManagementSlice.reducer;
