import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import CardTypography from "../Typography/CardTypography";

import Chip from "../misc/Chip";

const CustomerFixedFee = ({ fixed_fee, callback_editFixedFee }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
    maxWidth: "400px",
  };

  const statusIcon = (status) => (status ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />);

  const click_editFee = ({ fixed_uuid }) => {
    callback_editFixedFee({ fixed_uuid });
  };

  return (
    <Box sx={neumorphicStyles}>
      <CardTypography variant="h4" sx={{ marginBottom: 2 }}>
        Customer Fixed Fee
      </CardTypography>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ marginBottom: 1 }}>
        <CardTypography>
          <strong>Fixed Name:</strong> {fixed_fee.fixed_name || null}
        </CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1 }}>
        <CardTypography>
          <strong>Fixed Amount:</strong> {fixed_fee.fixed_amount || null}
        </CardTypography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {fixed_fee.fixed_amount ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
        <Chip label={fixed_fee.customer_fixed_fee_verified ? "Verified" : "Unverified"} verified={fixed_fee.customer_fixed_fee_verified} />
      </Box>

<Box sx={{ marginBottom: 1 ,marginTop:1,paddingLeft:6}}>
      <CardTypography> {fixed_fee.customer_fixed_fee_verified_message || null}</CardTypography>
      </Box>
      <Box sx={{ marginBottom: 1 ,marginTop:1,paddingLeft:6}}>
    <CardTypography>Date Created: {fixed_fee.created_date?new Date(fixed_fee.created_date).toLocaleDateString():null}</CardTypography>
    </Box>
      <Button
        variant="contained"
        onClick={() => click_editFee({ fixed_uuid: fixed_fee.fixed_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        Edit
      </Button>
    </Box>
  );
};

export default CustomerFixedFee;
