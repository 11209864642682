// src/pages/admin/customer_management/ViewChangeLog.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { fetchLandlordDetails } from "../../../redux/slices/landlordManagementSlice";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { fetchChangeLog } from "../../../redux/slices/admin";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";

import CardTypography from "../../../components/Typography/CardTypography";
import ColumnOutset from "../../../components/Grid/ColumnOutset";

const iconStyles = {
  cursor: "pointer",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  borderRadius: "50%",
  background: "#e0e0e0",
  padding: "5px",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
    boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.7)",
  },
};

function ViewChangeLog({ customer_uuid, onBack }) {
  const dispatch = useDispatch();

  const { changelog } = useSelector((state) => state.admin);
  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchChangeLog({ customer_uuid }));
    } else {
      alert("no user_uuid");
    }
  }, [customer_uuid, dispatch]);

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Changelog Management
          </Button>
          <CardTypography variant={"h3"}>Changelog</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={2} maxHeight="650px"></ColumnOutset>
        <ColumnInset xs={8} maxHeight="650px">
          {/* complex */}
          <Table maxHeight="650px">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Table Edited</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Edited By</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {changelog.map((row) => (
                <TableRow key={row.changelog_uuid}>
                  <TableCell>{row.created_date}</TableCell>
                  <TableCell>{row.data_type}</TableCell>
                  <TableCell>{row.customer}</TableCell>
                  <TableCell>{row.admin ? row.admin : row.customer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ColumnInset>
        <ColumnOutset xs={2} maxHeight="650px"></ColumnOutset>
      </Row>
    </Container>
  );
}

export default ViewChangeLog;
