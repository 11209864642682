import React from "react";
import { Tooltip, Button } from "@mui/material";

const IconButton = ({ icon, tooltip, callback }) => {
  return (
    <Tooltip title={tooltip || ""} arrow>
      <Button
        onClick={callback}
        sx={{
          backgroundColor: "#e0e0e0",
          color: "#333",
          borderRadius: "50%",
          minWidth: "48px",
          minHeight: "48px",
          padding: "12px",
          boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)", // Neumorphic shadow
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
          },
        }}
      >
        {icon}
      </Button>
    </Tooltip>
  );
};

export default IconButton;
