import React, { useState } from "react";
import { Box, MenuItem, Select, InputLabel, TextField, Button } from "@mui/material";

const SearchSelect = ({ type, label, data, onFilter }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isButtonVisible, setButtonVisible] = useState(false);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setButtonVisible(true);
  };

  const handleOkClick = () => {
    if (onFilter) {
      onFilter(selectedValue);
    }
    setButtonVisible(false); // Reset after triggering callback
  };

  const commonStyles = {
    boxShadow: "4px 4px 16px #bebebe, -2px -2px 16px #ffffff",
    borderRadius: "12px",
    backgroundColor: "#e0e0e0",
  };
  const compactMenuItemStyles = {
    padding: "2px 8px", // Very compact padding
    fontSize: "0.75rem", // Smaller font size
  };

  const compactSelectStyles = {
    padding: "0px", // Remove vertical padding entirely
    fontSize: "0.8rem", // Smaller font size
    minHeight: "30px", // Set explicit height
    lineHeight: "1.2", // Adjust line height for compactness
    "& .MuiSelect-select": {
      padding: "2px 8px", // Compact padding for the Select dropdown
    },
  };

  const compactTextFieldStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px", // Remove internal padding
      fontSize: "0.8rem", // Smaller font size
      height: "30px", // Set explicit height
      lineHeight: "1.2", // Adjust line height
      "& input": {
        padding: "4px", // Minimal padding for the input field
      },
    },
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: "5px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <InputLabel sx={{ fontSize: "1rem", marginBottom: "2px" }}>{label}</InputLabel>
        {type === "dropdown" ? (
          <Select value={selectedValue} onChange={handleChange} displayEmpty sx={{ ...commonStyles, ...compactSelectStyles }}>
            <MenuItem value="" disabled sx={compactMenuItemStyles}>
              Select an option
            </MenuItem>
            {data.map((item, index) => (
              <MenuItem key={index} value={item.key} sx={compactMenuItemStyles}>
                {item.value}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.target.value);
              setButtonVisible(true);
            }}
            variant="outlined"
            sx={{ ...commonStyles, ...compactTextFieldStyles }}
          />
        )}
      </Box>
      {isButtonVisible && (
        <Button
          variant="contained"
          sx={{
            ...commonStyles,
            padding: "5px 10px",
            fontSize: "0.8rem",
            textTransform: "capitalize",
          }}
          onClick={handleOkClick}
        >
          Ok
        </Button>
      )}
    </Box>
  );
};

export default SearchSelect;
