// src/components/specific/Customer_bank.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer_bank } from "../../../../redux/slices/admin";
import { toast } from "react-toastify";
import { Box, Typography, Button } from "@mui/material";
import Input from "../../../../components/fields/Input";
import Table from "../../../../components/table/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import Row from "../../../../components/Grid/Row";
import ColumnInset from "../../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../../components/Grid/ColumnHeading";

const neumorphicButtonStyle = {
  marginTop: 2,
  marginRight: 4,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const CustomerBank = ({ initial_bank, customer_uuid }) => {
  const dispatch = useDispatch();

  const [bank, setBank] = useState(initial_bank);
  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState(null); // 'approved' | 'rejected'
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedBank, setEditedBank] = useState({ ...bank });

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const getPartName = () => "Bank Details";

  const handleApprove = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer_bank",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus("approved");
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving bank:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer_bank",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus("rejected");
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting bank:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        update__customer_bank({
          customer_uuid,
          data: editedBank,
        })
      );

      if (update__customer_bank.fulfilled.match(resultAction)) {
        toast.success("Bank details updated successfully.");
        setIsEditing(false);
      } else {
        toast.error(resultAction.payload || "Failed to update bank details.");
      }
    } catch (err) {
      console.error("Error updating bank details:", err);
      toast.error("An unexpected error occurred while updating bank details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <ColumnHeading xs={12}>
        <Typography variant="h5" gutterBottom>
          Bank Details
        </Typography>
        <IconButton onClick={() => setIsEditing(true)} aria-label="edit">
          <EditIcon />
        </IconButton>
      </ColumnHeading>

      <ColumnInset xs={10}>
        <Table maxHeight="600px">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
  {Object.entries(bank).map(([key, value]) => {
    if (
      [
        "customer_account_number",
        "customer_account_holder",
        "customer_bank",
        "customer_branch_code",
        "customer_verified",
        "customer_verified_message",
      ].includes(key)
    ) {
      return (
        <TableRow key={key}>
          <TableCell>
            <strong>{key.replace(/_/g, " ")}</strong>
          </TableCell>
          <TableCell>
            {isEditing && key !== "customer_verified" ? (
              key === "customer_bank" ? (
                <select
                  style={{
                    width: "100%",
                    padding: "8px 12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    background: "#e0e0e0",
                    boxShadow: "inset 4px 4px 8px rgba(0, 0, 0, 0.2), inset -4px -4px 8px rgba(255, 255, 255, 0.5)",
                  }}
                  value={editedBank[key] || ""}
                  onChange={(e) =>
                    setEditedBank({
                      ...editedBank,
                      [key]: e.target.value,
                    })
                  }
                >
                  <option value="">Select Bank</option>
                  <option value="FNB">FNB</option>
                  <option value="Capitec">Capitec</option>
                  <option value="Standard Bank">Standard Bank</option>
                </select>
              ) : (
                <Input
                  maxWidth="100%"
                  inputPadding="2px 12px"
                  marginBottom="0px"
                  labelPadding="0px 8px"
                  value={editedBank[key] || ""}
                  onChange={(e) =>
                    setEditedBank({
                      ...editedBank,
                      [key]: e.target.value,
                    })
                  }
                  disabled={key.endsWith("_uuid")}
                />
              )
            ) : key === "customer_verified" ? (
              value ? (
                <CheckCircleIcon style={{ color: "green" }} />
              ) : (
                <CancelIcon style={{ color: "red" }} />
              )
            ) : (
              value
            )}
          </TableCell>
        </TableRow>
      );
    }
    return null;
  })}
</TableBody>

        </Table>
      </ColumnInset>

      <ColumnInset xs={2}>
        {isEditing ? (
          <>
            <Button sx={neumorphicButtonStyle} onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            <Button
              sx={neumorphicButtonStyle}
              onClick={() => {
                setIsEditing(false);
                setEditedBank({ ...bank });
              }}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Input placeholder="Message" value={approvalMessages} onChange={(e) => handleMessageChange(e.target.value)} />
            <Button sx={neumorphicButtonStyle} onClick={handleApprove} disabled={loading}>
              Approve
            </Button>
            <Button sx={neumorphicButtonStyle} onClick={handleReject} disabled={loading}>
              Reject
            </Button>
          </>
        )}
      </ColumnInset>
    </Row>
  );
};

export default CustomerBank;
