import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { fetchDocuments, editDocuments } from "../../../redux/slices/universalSlice";

import Input from "../../../components/fields/Input";
import CardTypography from "../../../components/Typography/CardTypography";
import UploadFile from "../../../components/buttons/UploadFile";
import Chip from "../../../components/misc/Chip";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

function EditDocuments({ customer_uuid, onBack }) {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const { customer_documents } = useSelector((state) => state.universalSlice);
  const [loadingStatus, setLoadingStatus] = useState({}); // Track loading status per document

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchDocuments({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  const handleFileUpload = async (file, document_uuid, document_type) => {
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("user_uuid", user_uuid);
    formData.append("customer_uuid", customer_uuid);
    formData.append("document_uuid", document_uuid);
    formData.append(document_type, file);
    setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));

    try {
      const resultAction = await dispatch(editDocuments({ formData })).unwrap();
      console.log("Upload successful:", resultAction);
    } catch (err) {
      console.error("Error uploading document:", err);
      alert("Failed to upload document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Documents</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={12}>
          {customer_documents.length === 0 ? (
            <Typography>No documents available.</Typography>
          ) : (
            <Table maxHeight="800px">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Size</TableCell>
                  <TableCell>MimeType</TableCell>
                  <TableCell>Document verified</TableCell>
                  <TableCell>Document verified Message</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer_documents.map((doc, index) => (
                  <TableRow key={doc.document_uuid}>
                    <TableCell>{doc.document_name}</TableCell>
                    <TableCell> {doc.document_size ? `${(doc.document_size / (1024 * 1024)).toFixed(2)} MB` : ""}</TableCell>
                    <TableCell> {doc.document_mimetype} </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {doc.document_filename ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={doc.document_verified ? "Verified" : "Unverified"} verified={doc.document_verified} />
                      </Box>
                    </TableCell>
                    <TableCell>{doc.document_verified_message} </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", gap: "25px" }}>
                        <TableButton onClick={() => window.open(`${process.env.REACT_APP_API_URL}/document_uploads/${doc.document_filename}`, "_blank")}>View</TableButton>
                        <UploadFile label="Upload" tooltip="Upload a new document here" callback={(file) => handleFileUpload(file, doc.document_uuid, doc.document_type)} disabled={loadingStatus[doc.document_uuid]} disabled_tooltip="Uploading..." enabled_tooltip="Upload a new document" />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditDocuments;
