import React from "react";
import { Chip as MuiChip } from "@mui/material";

const Chip = ({ label, verified }) => {
  return (
    <MuiChip
      label={label}
      sx={{
        backgroundColor: verified ? "#2196f3" : "#b0bec5",
        color: "#fff",
        boxShadow: verified
          ? "4px 4px 8px #1976d2, -4px -4px 8px #90caf9"
          : "4px 4px 8px #90a4ae, -4px -4px 8px #eceff1",
        borderRadius: "12px",
        padding: "2px 2px",
        fontSize: "0.85rem",
        fontWeight: "bold",
        textTransform: "capitalize",
      }}
    />
  );
};

export default Chip;
