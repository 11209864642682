import React from "react";
import { Box } from "@mui/material";

const HorizontalDivider = ({ width = "100%", margin = 2 }) => {
  return (
    <Box
      sx={{
        width: width, // Set the width based on the prop
        height: "4px", // Fixed height for the divider
        backgroundColor: "#e0e0e0",
        margin: `${margin}px auto`, // Apply margin and center the divider
        borderRadius: "8px", // Rounded edges for a neumorphic look
        boxShadow: "inset 2px 2px 4px #bebebe, inset -2px -2px 4px #ffffff", // Neumorphic shadow
      }}
    ></Box>
  );
};

export default HorizontalDivider;
