// src/pages/admin/customer_management/ChangeLogManagement.jsx
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { fetchLandlordDetails } from "../../../redux/slices/landlordManagementSlice";

import { TableBody, TableCell, TableHead, TableRow, Button, Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { fetchCustomers } from "../../../redux/slices/customerManagementSlice";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Table from "../../../components/table/Table";
import TableButton from "../../../components/table/TableButton";
import TableButtonGroup from "../../../components/table/TableButtonGroup";

import LandlordCard from "../../../components/cards/LandlordCard";
import RepresentativeCard from "../../../components/cards/RepresentativeCard";
import CommunicationCard from "../../../components/cards/CommunicationCard";

import CardTypography from "../../../components/Typography/CardTypography";
import Chip from "../../../components/misc/Chip";
import HorizontalDivider from "../../../components/misc/HorizontalDivider";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import ViewChangeLog from "./ViewChangeLog";

const iconStyles = {
  cursor: "pointer",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  borderRadius: "50%",
  background: "#e0e0e0",
  padding: "5px",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "scale(1.1)",
    boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.7)",
  },
};

function ChangeLogManagement() {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const [currentView, setCurrentView] = useState("ChangeLogManagement");

  const {
    customers,
    loading,
    error, //
  } = useSelector((state) => state.customerSlice);
  useEffect(() => {
    if (user_uuid) {
      dispatch(fetchCustomers({ user_uuid }));
    } else {
      alert("no user_uuid");
    }
  }, [user_uuid, currentView, dispatch]);

  const click__viewChangeLog = ({ customer }) => {
    setCurrentView(() => <ViewChangeLog customer_uuid={customer.customer_uuid} onBack={() => setCurrentView("ChangeLogManagement")} heading="back to ChangeLog Management" />);
  };

  if (typeof currentView !== "string") {
    return currentView;
  }

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12} paddingBottom={3}>
          <CardTypography variant={"h3"}>Change Log Management</CardTypography>
        </ColumnHeading>
      </Row>

      {/* Row 1 */}
      <Column xs={12} maxHeight={"800px"}>
        <Row>
          <ColumnInset xs={12} maxHeight="350px">
            {/* complex */}
            <Table maxHeight="200px">
              <TableHead>
                <TableRow>
                  <TableCell>customer_uuid</TableCell>
                  <TableCell>user_type</TableCell>
                  <TableCell>customer_email</TableCell>
                  <TableCell>customer_cell</TableCell>
                  <TableCell>customer_id</TableCell>
                  <TableCell>Customer details</TableCell>
                  <TableCell>banking details</TableCell>
                  <TableCell>Representative Details</TableCell>
                  <TableCell>Communication Details</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((row) => (
                  <TableRow key={row.customer_uuid}>
                    <TableCell>{row.customer_uuid}</TableCell>
                    <TableCell>{row.user_type}</TableCell>
                    <TableCell>{row.customer_email}</TableCell>
                    <TableCell>{row.customer_cell}</TableCell>
                    <TableCell>{row.customer_id}</TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip label={row.customer_verified ? "Verified" : "Unverified"} verified={row.customer_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.customer_account_number ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.bank_verified ? "Verified" : "Unverified"} verified={row.bank_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.customer_representative ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.representative_verified ? "Verified" : "Unverified"} verified={row.representative_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.communication_landlord || row.communication_representative ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
                        <Chip label={row.communication_verified ? "Verified" : "Unverified"} verified={row.communication_verified} />
                      </Box>
                    </TableCell>

                    <TableCell>
                      <TableButtonGroup>
                        <TableButton onClick={() => click__viewChangeLog({ customer: row })}>View</TableButton>
                      </TableButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ColumnInset>
        </Row>

        <Row>{/* cards */}</Row>
      </Column>
    </Container>
  );
}

export default ChangeLogManagement;
