// src/components/layout/AdminLayout.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
import SideNavBar from "../pages/admin/navigation/SideNavBar";
import { Outlet } from "react-router-dom";

function AdminLayout() {
  return (
    <Box sx={{ display: "flex" }}>
      <SideNavBar />
      <Box
        component="main"
        sx={{
          marginLeft: "183px",
          padding: 3,
          width: "100%",
          minHeight: "100vh",
          backgroundColor: "#e0e0e0", // backgroundColor: "theme.palette.blackShades.light",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default AdminLayout;
