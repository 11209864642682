// Column.jsx
import React from "react";
import { Grid, Box } from "@mui/material";

const Column = ({ xs, children, maxHeight = null }) => (
  <Grid
    item
    xs={xs}
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2,
      maxHeight: maxHeight || "none",
      overflowY: maxHeight ? "auto" : "visible",
    }}
  >
    <Box>{children}</Box>
  </Grid>
);

export default Column;
