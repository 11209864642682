import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { initialState } from "../initialState";

export const fetchCustomers = createAsyncThunk("customerManagement/fetchCustomers", async ({ user_uuid }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/customerManagement/customerManagement-onload`, { user_uuid });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || "Failed to fetch details");
  }
});

const customerManagementSlice = createSlice({
  name: "customerManagement",
  initialState: {
    ...initialState,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.customers = action.payload.customers;
        console.log(action.payload);
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        console.log(action);
        state.loading = false;
        state.error = action.payload.message || "An error occurred";
        toast.error(state.error);
      });
  },
});

export default customerManagementSlice.reducer;
