// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import adminReducer from "./slices/admin";
// import customerReducer from "./slices/CustomerSlice";
// import tenantReducer from "./slices/TenantSlice";
import uiReducer from "./slices/uiSlice";
import universalReducer from "./slices/universalSlice";

import customerReducer from "./slices/customerManagementSlice";
import landlordReducer from "./slices/landlordManagementSlice";
import complexReducer from './slices/complexManagementSlice'
import tenantReducer from './slices/tenantManagementSlice'

const store = configureStore({
  reducer: {
    ui: uiReducer,
    admin: adminReducer,
    auth: authReducer,

    customerSlice: customerReducer,
    landlordSlice: landlordReducer,
    complexSlice:complexReducer,
    tenantSlice: tenantReducer,
    
    universalSlice: universalReducer,


  },
});

export default store;
