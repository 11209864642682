import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";

import { fetchMeter, editMeter } from "../../../redux/slices/universalSlice";
import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

const EditMeter = ({ meter_uuid, tenant_uuid, onBack, heading }) => {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const customer_uuid = useSelector((state) => state.auth.customer_uuid);

  const [formData, setFormData] = useState({
    meter_number: "",
    list_meter: null,
    meter_sgc: "",
    meter_tariff: "",
  });
  const [errors, setErrors] = useState({});
  const [manufacturer, setManufacturer] = useState("");
  const [availableMeters, setAvailableMeters] = useState([]);
  const [selectedListMeter, setSelectedListMeter] = useState(null);

  const { meter, processed__list_meter } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (meter_uuid) {
      dispatch(fetchMeter({ meter_uuid }));
    }
  }, [meter_uuid, dispatch]);

  useEffect(() => {
    if (meter) {
      setFormData({
        meter_number: meter.meter_number || "",
        list_meter: meter || null,
        meter_sgc: meter.meter_sgc || "",
        meter_tariff: meter.meter_tariff || "",
      });
      setManufacturer(meter.meter_manufacturer || "");
      setSelectedListMeter(meter); // Set the selected meter initially
    }
  }, [meter]);

  useEffect(() => {
    if (manufacturer) {
      setAvailableMeters(processed__list_meter[manufacturer] || []);
      setSelectedListMeter(null); // Clear selected meter when manufacturer changes
    }
  }, [manufacturer, processed__list_meter]);

  useEffect(() => {
    if (selectedListMeter) {
      setFormData((prev) => ({ ...prev, list_meter: selectedListMeter }));
    }
  }, [selectedListMeter]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    console.log(formData)
  };

  const handleValidation = () => {
    const newErrors = {};

    if (!manufacturer) {
      newErrors.manufacturer = "Please select a manufacturer.";
    }

    if (!formData.list_meter) {
      newErrors.list_meter = "Please select a meter.";
    }

    if (!formData.meter_sgc) {
      newErrors.meter_sgc = "Please select a meter SGC.";
    }

    if (!formData.meter_tariff) {
      newErrors.meter_tariff = "Please select a meter tariff.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!handleValidation()) {
      return; // Prevent submission if validation fails
    }
    try {
      await dispatch(editMeter({ user_uuid, customer_uuid, meter_uuid, tenant_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating meter:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            {heading}
          </Button>
          <CardTypography variant={"h3"}>Edit Meter</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Box>
            <Input label="Meter Number" placeholder="Enter Meter Number" name="meter_number" value={formData.meter_number} onChange={handleChange} />

            <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.manufacturer}>
              <InputLabel id="manufacturer-select-label">Select Manufacturer</InputLabel>
              <Select labelId="manufacturer-select-label" value={manufacturer} 
              
              onChange={(e) => {
                setSelectedListMeter(null);
                setManufacturer(e.target.value)
                setFormData((prev) => ({ ...prev, list_meter: null }));
              }}
              
              >
                {Object.keys(processed__list_meter).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="caption" color="error">
                {errors.manufacturer}
              </Typography>
            </FormControl>

            {availableMeters && (
              <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.list_meter}>
                <InputLabel id="meter-select-label">Select Meter</InputLabel>
                <Select
                  labelId="meter-select-label"
                  value={formData.list_meter ? formData.list_meter.meter_code : ""}
                  onChange={(e) => {
                    const selectedCode = e.target.value;
                    const meter = availableMeters.find((m) => m.meter_code === selectedCode);
                    setSelectedListMeter(meter);
                    setFormData((prev) => ({ ...prev, list_meter: meter }));
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {availableMeters.map((m) => (
                    <MenuItem key={m.list_meter_uuid} value={m.meter_code}>
                      {m.meter_code} - {m.meter_description}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="error">
                  {errors.list_meter}
                </Typography>
              </FormControl>
            )}

            <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.meter_sgc}>
              <InputLabel id="meter-sgc-label">Meter SGC</InputLabel>
              <Select
                labelId="meter-sgc-label"
                name="meter_sgc"
                value={formData.meter_sgc}
                onChange={(e) => setFormData((prev) => ({ ...prev, meter_sgc: e.target.value }))}
                sx={{
                  backgroundColor: "#e0e0e0",
                  boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                }}
              >
                <MenuItem value="1234">1234</MenuItem>
              </Select>
              <Typography variant="caption" color="error">
                {errors.meter_sgc}
              </Typography>
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.meter_tariff}>
              <InputLabel id="meter-tariff-label">Meter Tariff</InputLabel>
              <Select
                labelId="meter-tariff-label"
                name="meter_tariff"
                value={formData.meter_tariff}
                onChange={(e) => setFormData((prev) => ({ ...prev, meter_tariff: e.target.value }))}
                sx={{
                  backgroundColor: "#e0e0e0",
                  boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                }}
              >
                <MenuItem value="1">1</MenuItem>
              </Select>
              <Typography variant="caption" color="error">
                {errors.meter_tariff}
              </Typography>
            </FormControl>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <LoaderButton buttonText="Cancel" onClick={onBack} />
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
};

export default EditMeter;
