// src/components/Grid/Container.jsx
import React from "react";
import { Grid } from "@mui/material";

const Container = ({ children }) => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "#e0e0e0",
        boxShadow: "8px 8px 16px #bebebe, -8px -8px 16px #ffffff", // Neumorphic shadow
        borderRadius: "16px",
        padding: 2,
      }}
    >
      {children}
    </Grid>
  );
};

export default Container;
