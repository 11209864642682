import React from "react";
import { Box, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";

import CardTypography from "../Typography/CardTypography";

import Chip from "../misc/Chip";

const LandlordCard = ({ customer, customer_documents, callback_editlandlord, callback_editbank, callback_editdocuments }) => {
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
  };
  const iconStyles = {
    cursor: "pointer",
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    background: "#e0e0e0",
    padding: "5px",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.7)",
    },
  };

  const click_editlandlord = ({ customer_uuid }) => {
    callback_editlandlord({ customer_uuid });
  };
  const click_editbank = ({ customer_uuid }) => {
    callback_editbank({ customer_uuid });
  };
  const click_editdocuments = ({ customer_uuid }) => {
    callback_editdocuments({ customer_uuid });
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
      {/* Landlord Details */}
      <CardTypography variant="h4">Landlord Details</CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>
          Name: {customer.customer_name} {customer.customer_sname}
        </CardTypography>
        <CardTypography>Cell: {customer.customer_cell}</CardTypography>
        <CardTypography>Email: {customer.customer_email}</CardTypography>
        <CardTypography>ID: {customer.customer_id}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip label={customer.customer_verified ? "Verified" : "Unverified"} verified={customer.customer_verified} />
        </Box>
      </Box>
      <Button
        onClick={() => click_editlandlord({ customer_uuid: customer.customer_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>

      {/* Bank Details */}
      <CardTypography variant="h4" sx={{ marginTop: 4 }}>
        Bank Details
      </CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>Account: {customer.customer_account_number}</CardTypography>
        <CardTypography>Branch: {customer.customer_branch_code}</CardTypography>
        <CardTypography>Name: {customer.customer_bank}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {customer.customer_account_number ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
          <Chip label={customer.bank_verified ? "Verified" : "Unverified"} verified={customer.bank_verified} />
        </Box>
      </Box>
      <Button
        onClick={() => click_editbank({ customer_uuid: customer.customer_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>

      {/* Document Details */}
      <CardTypography variant="h4" sx={{ marginTop: 4 }}>
        Document Details
      </CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        {customer_documents.map((doc, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginY: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {doc.document_uuid ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
              <Chip label={doc.document_verified ? "Verified" : "Unverified"} verified={doc.document_verified} />

              <CardTypography>{doc.document_name}</CardTypography>
            </Box>
            {/* <DownloadIcon sx={iconStyles} /> */}
          </Box>
        ))}
      </Box>
      <Button
        onClick={() => click_editdocuments({ customer_uuid: customer.customer_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>
    </Box>
  );
};

export default LandlordCard;
