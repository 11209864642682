// src/components/common/NavButton.jsx
import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const StyledNavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "variantType",
})(({ theme, variantType }) => {
  // Define base styles
  const baseStyles = {
    width: "100%",
    justifyContent: "flex-start",
    padding: theme.spacing(1,2),
    marginBottom: theme.spacing(6),
    backgroundColor: theme.palette.blackShades.medium,
    color: theme.palette.silverShades.light,
    boxShadow: `5px 5px 10px ${theme.palette.blackShades.black}, -5px -5px 10px ${theme.palette.blackShades.dark}`,
    borderRadius: "10px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: theme.palette.blackShades.black,
      color: theme.palette.bronzeShades.bronze4,
      boxShadow: `3px 3px 6px ${theme.palette.blackShades.dark}, -3px -3px 6px ${theme.palette.blackShades.light}`,
    },
    "&.active": {
      backgroundColor: theme.palette.blackShades.dark,
      color: theme.palette.bronzeShades.bronze1,
      boxShadow: `2px 2px 4px ${theme.palette.blackShades.dark}, -2px -2px 4px ${theme.palette.blackShades.light}`,
    },
  };

  // Define styles for the logout variant
  const logoutStyles =
    variantType === "logout"
      ? {
          background: theme.palette.blackShades.medium,
          color: theme.palette.silverShades.dark, // Subtle neumorphic shadows
          borderRadius: "10px", // Rounded corners for a soft appearance
          transition: "all 0.3s ease", // Smooth transition for hover and active states

          "&:hover": {
            backgroundColor: theme.palette.blackShades.black,
            color: theme.palette.bronzeShades.bronze4,
            boxShadow: `3px 3px 6px ${theme.palette.blackShades.dark}, -3px -3px 6px ${theme.palette.blackShades.light}`,
          },
          "&.active": {
            backgroundColor: theme.palette.secondary.main, // Maintain background color when active
            boxShadow: `1px 1px 2px ${theme.palette.shadows.dark}, -1px -1px 2px ${theme.palette.shadows.light}`, 
          },
        }
      : {};

  return {
    ...baseStyles,
    ...logoutStyles,
  };
});

function NavButton({ to, children, isActive, onClick, variant, ...props }) {
  return (
    <StyledNavButton
      component={to ? RouterLink : "button"}
      to={to}
      onClick={onClick}
      className={isActive ? "active" : ""}
      variantType={variant} // Pass variant to styled component
      {...props}
    >
      {children}
    </StyledNavButton>
  );
}

NavButton.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["default", "logout"]),
};

NavButton.defaultProps = {
  to: null,
  isActive: false,
  onClick: () => {},
  variant: "default",
};

export default NavButton;
