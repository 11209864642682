import React, { useState } from "react";
import { TextField, Box, Button, Typography } from "@mui/material";
import CardTypography from "../Typography/CardTypography";

const TenantForm = ({ onSubmit, onCancel }) => {
  const [formValues, setFormValues] = useState({
    tenant_name: "Mich ael",
    tenant_sname: "Mar a is",
    tenant_email: "mike@gmail.com",
    tenant_cell: "0799440682",
    tenant_id: "9708045114088",
    tenant_unit: "1",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on input change
  };

  const handleValidation = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        newErrors[key] = "This field is required.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      onSubmit(formValues);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "auto",
        padding: 1,
        borderRadius: 3,
        backgroundColor: "#e0e0e0",
        boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
      }}
    >
      <CardTypography variant="h5" sx={{ marginBottom: 2 }}>
        Tenant Form
      </CardTypography>
      {Object.keys(formValues).map((field) => (
        <TextField
          key={field}
          fullWidth
          variant="outlined"
          label={field.replace("_", " ").toUpperCase()}
          name={field}
          value={formValues[field]}
          onChange={handleChange}
          error={!!errors[field]}
          helperText={errors[field] || " "}
          sx={{
            marginBottom: 1,
            backgroundColor: "#e0e0e0",
            boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
            borderRadius: 2,
          }}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 3,
        }}
      >
        <Button
          onClick={onCancel}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)", // Same shadow as UploadFile
            "&:hover": {
              backgroundColor: "#e5e5e5",
              boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)", // Same shadow as UploadFile
            "&:hover": {
              backgroundColor: "#e5e5e5",
              boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default TenantForm;
