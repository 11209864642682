// src/components/layout/CustomerLayout.jsx
import React from "react";
import { Box, Typography } from "@mui/material";
import SideNavBar from "../pages/customer/navigation/SideNavBar";
import { Outlet } from "react-router-dom";

function CustomerLayout() {
  return (
    <Box sx={{ display: "flex" }}>
      <SideNavBar />
      <Box
        component="main"
        sx={{
          marginLeft: "183px",
          padding: "30px",
          width: "calc(100vw - 183px)",
          minHeight: "100vh",
          backgroundColor: "#e0e0e0", // backgroundColor: "theme.palette.blackShades.light",
          overflowY: "auto",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default CustomerLayout;
