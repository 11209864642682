// src/components/specific/Customer_representative.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer_representative } from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Button, IconButton, Typography } from "@mui/material";
import Input from "../../../../components/fields/Input";
import Table from "../../../../components/table/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";

import Row from "../../../../components/Grid/Row";
import ColumnInset from "../../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../../components/Grid/ColumnHeading";

const neumorphicButtonStyle = {
  marginTop: 2,
  marginRight: 4,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Customer_representative = ({ representative, customer_uuid }) => {
  const dispatch = useDispatch();

  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState(null); // 'approved' | 'rejected'
  const [loading, setLoading] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editedRepresentative, setEditedRepresentative] = useState({
    ...representative,
  });

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer_representative",
          id: representative.representative_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Representative approved successfully.");
        setActionStatus("approved");
      } else {
        toast.error(resultAction.payload || "Failed to approve representative.");
      }
    } catch (err) {
      console.error(`Error approving representative:`, err);
      toast.error("An unexpected error occurred while approving the representative.");
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer_representative",
          id: representative.representative_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Representative rejected successfully.");
        setActionStatus("rejected");
      } else {
        toast.error(resultAction.payload || "Failed to reject representative.");
      }
    } catch (err) {
      console.error(`Error rejecting representative:`, err);
      toast.error("An unexpected error occurred while rejecting the representative.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedRepresentative({ ...representative }); // Reset edited data
  };

  const handleSaveEdit = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        update__customer_representative({
          representative_uuid: representative.representative_uuid,
          data: editedRepresentative,
        })
      );

      if (update__customer_representative.fulfilled.match(resultAction)) {
        toast.success("Representative details updated successfully.");
        setIsEditing(false);
      } else {
        toast.error(resultAction.payload || "Failed to update representative details.");
      }
    } catch (err) {
      console.error("Error updating representative details:", err);
      toast.error("An unexpected error occurred while updating representative details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <ColumnHeading xs={12}>
        <Typography variant="h5" gutterBottom>
          Customer Representative
        </Typography>
        <IconButton onClick={handleEdit} aria-label="edit">
          <EditIcon />
        </IconButton>
      </ColumnHeading>

      <ColumnInset xs={10}>
        <Table maxHeight="600px">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(representative).map(([key, value]) => {
              if (["representative_uuid", "representative_name", "representative_sname", "representative_email", "representative_cell", "representative_id", "representative_verified", "representative_verified_message", "created_date"].includes(key)) {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <strong>{key.replace(/_/g, " ")}</strong>
                    </TableCell>
                    <TableCell>
                      {isEditing && key !== "representative_verified" ? (
                        <Input
                          value={editedRepresentative[key] || ""}
                          onChange={(e) =>
                            setEditedRepresentative({
                              ...editedRepresentative,
                              [key]: e.target.value,
                            })
                          }
                          maxWidth="100%"
                          inputPadding="6px 12px"
                          marginBottom="2px"
                          labelPadding="0px 8px"
                          disabled={key.endsWith("_uuid")}
                        />
                      ) : key === "representative_verified" ? (
                        value ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : (
                          <CancelIcon style={{ color: "red" }} />
                        )
                      ) : (
                        value
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </ColumnInset>

      <ColumnInset xs={2}>
        {isEditing ? (
          <>
            <Button sx={neumorphicButtonStyle} onClick={handleSaveEdit} startIcon={<SaveIcon />} disabled={loading}>
              Save
            </Button>
            <Button sx={neumorphicButtonStyle} onClick={handleCancelEdit} disabled={loading}>
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Input placeholder="Verification message" value={approvalMessages} onChange={(e) => handleMessageChange(e.target.value)} />
            {actionStatus === "approved" ? (
              <>
                <CheckCircleIcon color="success" />
                <Typography>Approved</Typography>
              </>
            ) : actionStatus === "rejected" ? (
              <>
                <CancelIcon color="error" />
                <Typography>Rejected</Typography>
              </>
            ) : (
              <>
                <Button sx={neumorphicButtonStyle} onClick={handleApprove} disabled={loading}>
                  Approve
                </Button>
                <Button sx={neumorphicButtonStyle} onClick={handleReject} disabled={loading}>
                  Reject
                </Button>
              </>
            )}
          </>
        )}
      </ColumnInset>
    </Row>
  );
};

export default Customer_representative;
