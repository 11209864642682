// src/pages/admin/meter_management/WebsiteManagement.jsx
import React from 'react';
import { Typography } from '@mui/material';
import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";
import CardTypography from "../../../components/Typography/CardTypography";
function WebsiteManagement() {
  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Typography variant={"h3"}>Website Management</Typography>
        </ColumnHeading>
      </Row>

      {/* Row 1 */}
      <Row>
        <ColumnInset xs={3}>
        <Typography variant={"h5"}>update page1: (specials) text & images</Typography>
        </ColumnInset>
        <ColumnOutset xs={3}>
        <Typography variant={"h5"}>update page2: (info) text & images</Typography>
        </ColumnOutset>
        <ColumnInset xs={3}>
        <Typography variant={"h5"}>update page3: (about us) text & images</Typography>
        </ColumnInset>
        <ColumnOutset xs={3}>
        <Typography variant={"h5"}>update page4: (get in touch) text & images</Typography>
        </ColumnOutset>
      </Row>


    </Container>
  );
}

export default WebsiteManagement;
