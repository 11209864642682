// src/components/specific/Customer.jsx

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer } from "../../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Box, Typography, Button } from "@mui/material";
import Input from "../../../../components/fields/Input";
import Table from "../../../../components/table/Table";
import TableButton from "../../../../components/table/TableButton";
import TableButtonGroup from "../../../../components/table/TableButtonGroup";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import Row from "../../../../components/Grid/Row";
import ColumnInset from "../../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../../components/Grid/ColumnHeading";

const neumorphicButtonStyle = {
  marginTop: 2,
  marginRight: 4,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Customer = ({ initial_customer, customer_uuid }) => {
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState(initial_customer);
  const [approvalMessages, setApprovalMessages] = useState("");
  const [actionStatus, setActionStatus] = useState(null); // 'approved' | 'rejected'
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCustomer, setEditedCustomer] = useState({ ...customer });

  const handleMessageChange = (value) => {
    setApprovalMessages(value);
  };

  const getPartName = () => "Customer Details";

  const handleApprove = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} approved successfully.`);
        setActionStatus("approved");
      } else {
        toast.error(resultAction.payload || `Failed to approve ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error approving customer:`, err);
      toast.error(`An unexpected error occurred while approving ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer",
          id: customer_uuid,
          message: approvalMessages,
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success(`${getPartName()} rejected successfully.`);
        setActionStatus("rejected");
      } else {
        toast.error(resultAction.payload || `Failed to reject ${getPartName()}.`);
      }
    } catch (err) {
      console.error(`Error rejecting customer:`, err);
      toast.error(`An unexpected error occurred while rejecting ${getPartName()}.`);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const resultAction = await dispatch(
        update__customer({
          customer_uuid,
          data: editedCustomer,
        })
      );

      if (update__customer.fulfilled.match(resultAction)) {
        toast.success("Customer details updated successfully.");
        setIsEditing(false);
      } else {
        toast.error(resultAction.payload || "Failed to update customer details.");
      }
    } catch (err) {
      console.error("Error updating customer details:", err);
      toast.error("An unexpected error occurred while updating customer details.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row>
      <ColumnHeading xs={12}>
        <Typography variant="h5" gutterBottom>
          Customer Details
        </Typography>
        <IconButton onClick={() => setIsEditing(true)} aria-label="edit">
          <EditIcon />
        </IconButton>
      </ColumnHeading>

      <ColumnInset xs={10}>
        <Table maxHeight="600px">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(customer).map(([key, value]) => {
              if (["customer_uuid", "user_type", "company_name", "company_type", "company_vat_number", "customer_name", "customer_sname", "customer_email", "customer_cell", "customer_id", "customer_representative", "customer_verified", "customer_verified_message", "created_date"].includes(key)) {
                return (
                  <TableRow key={key}>
                    <TableCell>
                      <strong>{key.replace(/_/g, " ")}</strong>
                    </TableCell>
                    <TableCell>{isEditing && key !== "customer_representative" && key !== "customer_verified" ? <Input maxWidth="100%" inputPadding="2px 12px" marginBottom="0px" labelPadding="0px 8px" value={editedCustomer[key] || ""} onChange={(e) => setEditedCustomer({ ...editedCustomer, [key]: e.target.value })} disabled={key.endsWith("_uuid")} /> : key === "customer_representative" || key === "customer_verified" ? value ? <CheckCircleIcon style={{ color: "green" }} /> : <CancelIcon style={{ color: "red" }} /> : value}</TableCell>
                  </TableRow>
                );
              }
              return null;
            })}
          </TableBody>
        </Table>
      </ColumnInset>

      <ColumnInset xs={2}>
        {isEditing ? (
          <>
            <Button sx={neumorphicButtonStyle} onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
            <Button
              sx={neumorphicButtonStyle}
              onClick={() => {
                setIsEditing(false);
                setEditedCustomer({ ...customer });
              }}
              startIcon={<CancelIcon />}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Input placeholder="Message" value={approvalMessages} onChange={(e) => handleMessageChange(e.target.value)} />
            <Button sx={neumorphicButtonStyle} onClick={handleApprove} disabled={loading}>
              Approve
            </Button>
            <Button sx={neumorphicButtonStyle} onClick={handleReject} disabled={loading}>
              Reject
            </Button>
          </>
        )}
      </ColumnInset>
    </Row>
  );
};

export default Customer;
