// src/components/specific/Customer_documents.jsx

import React, { useState } from "react";
import { toast } from "react-toastify";
import { Typography, Table, TableHead, TableBody, TableCell, TableRow, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { useDispatch } from "react-redux";
import { approve__customer_detail, reject__customer_detail, update__customer_document } from "../../../../redux/slices/admin"; // Ensure the correct path and define update__customer_document

import Input from "../../../../components/fields/Input";
import Row from "../../../../components/Grid/Row";
import ColumnInset from "../../../../components/Grid/ColumnInset";
import ColumnHeading from "../../../../components/Grid/ColumnHeading";
import TableButtonGroup from "../../../../components/table/TableButtonGroup";

const neumorphicButtonStyle = {
  marginTop: 2,
  marginRight:2,
  marginLeft:4,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Customer_documents = ({ documents, customer_uuid }) => {
  const dispatch = useDispatch();

  const [approvalMessages, setApprovalMessages] = useState({});
  const [actionStatus, setActionStatus] = useState({}); // { [document_uuid]: 'approved' | 'rejected' }
  const [loadingStatus, setLoadingStatus] = useState({}); // { [document_uuid]: boolean }

  // New state for editing
  const [editStatus, setEditStatus] = useState({}); // { [document_uuid]: boolean }
  const [selectedFiles, setSelectedFiles] = useState({}); // { [document_uuid]: File }

  const handleMessageChange = (document_uuid, value) => {
    setApprovalMessages((prev) => ({ ...prev, [document_uuid]: value }));
  };

  const getPartName = () => "Customer Documents";

  const handleApprove = async (document_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const resultAction = await dispatch(
        approve__customer_detail({
          customer_uuid,
          part: "customer_documents",
          id: document_uuid,
          message: approvalMessages[document_uuid] || "",
        })
      );

      if (approve__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Document approved successfully.");
        setActionStatus((prev) => ({ ...prev, [document_uuid]: "approved" }));
      } else {
        toast.error(resultAction.payload || "Failed to approve document.");
      }
    } catch (err) {
      console.error(`Error approving document ${document_uuid}:`, err);
      toast.error("An unexpected error occurred while approving the document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  const handleReject = async (document_uuid) => {
    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const resultAction = await dispatch(
        reject__customer_detail({
          customer_uuid,
          part: "customer_documents",
          id: document_uuid,
          message: approvalMessages[document_uuid] || "",
        })
      );

      if (reject__customer_detail.fulfilled.match(resultAction)) {
        toast.success("Document rejected successfully.");
        setActionStatus((prev) => ({ ...prev, [document_uuid]: "rejected" }));
      } else {
        toast.error(resultAction.payload || "Failed to reject document.");
      }
    } catch (err) {
      console.error(`Error rejecting document ${document_uuid}:`, err);
      toast.error("An unexpected error occurred while rejecting the document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  const handleEdit = (document_uuid) => {
    setEditStatus((prev) => ({ ...prev, [document_uuid]: true }));
  };

  const handleCancelEdit = (document_uuid) => {
    setEditStatus((prev) => ({ ...prev, [document_uuid]: false }));
    setSelectedFiles((prev) => ({ ...prev, [document_uuid]: null }));
  };

  const handleFileChange = (document_uuid, file) => {
    setSelectedFiles((prev) => ({ ...prev, [document_uuid]: file }));
  };

  const handleSaveEdit = async (document_uuid, document_type) => {
    const file = selectedFiles[document_uuid];
    if (!file) {
      toast.error("Please select a file to upload.");
      return;
    }

    try {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: true }));
      const formData = new FormData();
      formData.append("customer_uuid", customer_uuid);
      formData.append("document_uuid", document_uuid);
      formData.append(document_type, file);

      const resultAction = await dispatch(
        update__customer_document({
          formData,
        })
      );

      if (update__customer_document.fulfilled.match(resultAction)) {
        toast.success("Document updated successfully.");
        setEditStatus((prev) => ({ ...prev, [document_uuid]: false }));
      } else {
        toast.error(resultAction.payload || "Failed to update document.");
      }
    } catch (err) {
      console.error(`Error updating document ${document_uuid}:`, err);
      toast.error("An unexpected error occurred while updating the document.");
    } finally {
      setLoadingStatus((prev) => ({ ...prev, [document_uuid]: false }));
    }
  };

  return (
    <Row>
      <ColumnHeading xs={12}>
        <Typography variant="h5" gutterBottom>
          Customer Documents
        </Typography>
      </ColumnHeading>

      <ColumnInset xs={10}>
        <Table maxHeight="600px">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>MIME Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No documents available.
                </TableCell>
              </TableRow>
            ) : (
              documents.map((doc) => (
                <TableRow key={doc.document_uuid}>
                  <TableCell>{doc.document_type}</TableCell>
                  <TableCell>{doc.document_mimetype}</TableCell>
                  <TableCell>
                    {editStatus[doc.document_uuid] ? (
                      <>
                        <input type="file" onChange={(e) => handleFileChange(doc.document_uuid, e.target.files[0])} accept="application/pdf,image/*" />
                        <Button sx={neumorphicButtonStyle} onClick={() => handleSaveEdit(doc.document_uuid, doc.document_type)} disabled={loadingStatus[doc.document_uuid]}>
                          Save
                        </Button>
                        <Button sx={neumorphicButtonStyle} onClick={() => handleCancelEdit(doc.document_uuid)} disabled={loadingStatus[doc.document_uuid]}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                      <TableButtonGroup>
                        <Button sx={neumorphicButtonStyle} onClick={() => window.open(`${process.env.REACT_APP_API_URL}/document_uploads/${encodeURIComponent(doc.document_filename)}`, "_blank")}>
                          View
                        </Button>
                        <Input  inputPadding="6px 12px" marginBottom="2px" labelPadding="0px 8px" placeholder="Verification message" value={approvalMessages[doc.document_uuid] || ""} onChange={(e) => handleMessageChange(doc.document_uuid, e.target.value)} />

                        <Button sx={neumorphicButtonStyle} onClick={() => handleApprove(doc.document_uuid)} disabled={loadingStatus[doc.document_uuid]}>
                          Approve
                        </Button>
                        <Button sx={neumorphicButtonStyle} onClick={() => handleReject(doc.document_uuid)} disabled={loadingStatus[doc.document_uuid]}>
                          Reject
                        </Button>
                        <IconButton onClick={() => handleEdit(doc.document_uuid)} aria-label="edit">
                          <EditIcon />
                        </IconButton>
                        </TableButtonGroup>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </ColumnInset>

      <ColumnInset xs={2}></ColumnInset>
    </Row>
  );
};

export default Customer_documents;
