import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";

import { fetchCustomer, editBank } from "../../../redux/slices/universalSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditBank({ customer_uuid, onBack }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    customer_account_number: "",
    customer_account_holder: "",
    customer_bank: "",
    customer_branch_code: "",
  });

  const { customer } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchCustomer({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  useEffect(() => {
    setFormData({
      customer_account_number: customer.customer_account_number || "",
      customer_account_holder: customer.customer_account_holder || "",
      customer_bank: customer.customer_bank || "",
      customer_branch_code: customer.customer_branch_code || "",
    });
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const result = await dispatch(editBank({ customer_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Bank Details</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Input label="Account Number" placeholder="Account Number" name="customer_account_number" value={formData.customer_account_number} onChange={handleChange} />
          <Input label="Account Holder" placeholder="Account Holder" name="customer_account_holder" value={formData.customer_account_holder} onChange={handleChange} />
          <Input label="Bank" placeholder="Bank" name="customer_bank" value={formData.customer_bank} onChange={handleChange} />
          <Input label="Branch Code" placeholder="Branch Code" name="customer_branch_code" value={formData.customer_branch_code} onChange={handleChange} />

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditBank;
