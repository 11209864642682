// src/pages/AdminPage.jsx
import React from "react";
import { Typography } from "@mui/material";
import Container from "../../components/Grid/Container";
import Row from "../../components/Grid/Row";
import ColumnInset from "../../components/Grid/ColumnInset";
import ColumnOutset from "../../components/Grid/ColumnOutset";
import ColumnHeading from "../../components/Grid/ColumnHeading";
import CardTypography from "../../components/Typography/CardTypography";

function AdminPage() {
  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <CardTypography variant={"h3"}>Admin Home</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnInset xs={3}>
          <Typography variant={"h5"}>view/edit account?</Typography>
        </ColumnInset>
        <ColumnOutset xs={3}>
          <Typography variant={"h5"}>what else?</Typography>
        </ColumnOutset>
      </Row>
    </Container>
  );
}

export default AdminPage;
