import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Button, Switch, FormControlLabel } from "@mui/material";

import { fetchTenant,editTenant } from "../../../redux/slices/universalSlice";
//import { editTenant } from "../../../redux/slices/tenantManagementSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditTenant({ tenant_uuid, onBack, heading }) {
  const dispatch = useDispatch();
  const user_uuid = useSelector((state) => state.auth.user_uuid);
  const customer_uuid = useSelector((state) => state.auth.customer_uuid);

  const [formData, setFormData] = useState({
    tenant_name: "",
    tenant_sname: "",
    tenant_email: "",
    tenant_cell: "",
    tenant_id: "",
    tenant_unit: "",
    tenant_verified:false,
    tenant_verified_message:""
  });

  const { tenant } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (tenant_uuid) {
      dispatch(fetchTenant({ tenant_uuid }));
    }
  }, [tenant_uuid, dispatch]);

  useEffect(() => {
    setFormData({
      tenant_name: tenant.tenant_name || "",
      tenant_sname: tenant.tenant_sname || "",
      tenant_email: tenant.tenant_email || "",
      tenant_cell: tenant.tenant_cell || "",
      tenant_id: tenant.tenant_id || "",
      tenant_unit: tenant.tenant_unit || "",
      tenant_verified:tenant.tenant_verified||false,
      tenant_verified_message:tenant.tenant_verified_message||""
    });
  }, [tenant]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value,
      tenant_verified_message: '', }));
  };
  const handleToggle = (field) => {
    setFormData((prev) => ({ ...prev, [field]: !prev[field] }));
  };
  const handleSubmit = async () => {
    try {
      const result = await dispatch(editTenant({ user_uuid, customer_uuid, tenant_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            {heading}
          </Button>
          <CardTypography variant={"h3"}>Edit Tenant</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Box>
            <Input label="Name" placeholder="Tenant Name" name="tenant_name" value={formData.tenant_name} onChange={handleChange} />
            <Input label="Surname" placeholder="Tenant Surname" name="tenant_sname" value={formData.tenant_sname} onChange={handleChange} />
            <Input label="Email" placeholder="Tenant Email" name="tenant_email" value={formData.tenant_email} onChange={handleChange} type="email" />
            <Input label="Cell" placeholder="Tenant Cell" name="tenant_cell" value={formData.tenant_cell} onChange={handleChange} />
            <Input label="Id" placeholder="Tenant ID" name="tenant_id" value={formData.tenant_id} onChange={handleChange} />
            <Input label="Unit Nr" placeholder="Tenant Unit" name="tenant_unit" value={formData.tenant_unit} onChange={handleChange} />

            <FormControlLabel
              control={
                <Switch
                  checked={formData.tenant_verified}
                  onChange={() => handleToggle("tenant_verified")}
                  sx={{
                    "& .MuiSwitch-switchBase.Mui-checked": {
                      color: "green",
                    },
                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "green",
                    },
                    "& .MuiSwitch-switchBase": {
                      color: "red",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: formData.tenant_verified ? "green" : "red",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  Tenant Verified
                </Typography>
              }
            />
            <Input label="Tenant Verified Message" placeholder="Tenant Verified Message" name="tenant_verified_message" value={formData.tenant_verified_message} onChange={handleChange} />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditTenant;
