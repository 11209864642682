// src/components/Grid/Row.jsx
import React from "react";
import { Grid } from "@mui/material";

const Row = ({ spacing = 2,mt=0, children }) => {
  return (
    <Grid container spacing={spacing}    sx={mt > 0 ? { marginTop: mt } : {}}>
      {children}
    </Grid>
  );
};

export default Row;
