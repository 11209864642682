// src/Components/Laison.jsx
import React from "react";
import { Box, Typography, CardContent, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import placeholderImage from "../../../assets/suppliers/laison.png";

const NeumorphicCard = styled("div")(({ theme }) => ({
  backgroundColor: "rgba(0,0,0,0.85)",
  color: theme.palette.silverShades.light,
  boxShadow: `2px 2px 2px ${theme.palette.blackShades.medium}, -2px -2px 2px ${theme.palette.blackShades.light}`,
  borderRadius: "15px",
  width: "250px",
  padding: theme.spacing(2),
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    boxShadow: `5px 5px 15px ${theme.palette.blackShades.medium}, -5px -5px 15px ${theme.palette.blackShades.light}`,
    transform: "translateY(-5px)",
  },
}));

const MeterImage = styled("img")(({ theme }) => ({
  width: "150px",
  height: "auto",
  marginBottom: theme.spacing(2),
}));

function Laison() {
  const theme = useTheme();

  // All Laison meters
  const meters = [
    {
      uuid: "Ff8A09",
      code: "LA23LR",
      description: "Cold Water Meter DN15 from Laison.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "Xq8B0N",
      code: "LA23LR2",
      description: "Cold Water Meter DN20 from Laison.",
      dataSheet: "#",
      image: placeholderImage,
    },
    {
      uuid: "Za8C0Z",
      code: "LN23UN",
      description: "Hot Water DN20 meter from Laison.",
      dataSheet: "#",
      image: placeholderImage,
    },
  ];

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "rgb(0,0,0)",
        backgroundImage:
          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(20,20,20,1) 35%, rgba(41,41,41,1) 100%)",
        p: 4,
      }}
    >
      <Typography variant="h3" color="#fff" gutterBottom>
        Laison Meters
      </Typography>
      <Typography variant="body1" color="#fff" sx={{ mb: 4, maxWidth: 600 }}>
        View our Laison water meters for precise measurement and reliable performance.
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
        {meters.map((meter) => (
          <NeumorphicCard key={meter.uuid}>
            <CardContent sx={{ textAlign: "center" }}>
              <MeterImage src={meter.image} alt={meter.code} />
              <Typography variant="h6" sx={{ mb: 1 }}>
                {meter.code}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.silverShades.dark, mb: 2 }}
              >
                {meter.description}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                href={meter.dataSheet}
                target="_blank"
                rel="noopener"
              >
                Download Data Sheet
              </Button>
            </CardContent>
          </NeumorphicCard>
        ))}
      </Box>
    </Box>
  );
}

export default Laison;
