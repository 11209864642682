import React from "react";
import { Typography } from "@mui/material";

const CardTypography = ({ children, variant = "body1", sx = {} }) => {
  const defaultStyles = {
    textShadow: "1px 1px 2px rgba(0, 0, 0, 0.2), -1px -1px 2px rgba(255, 255, 255, 0.7)", // Neumorphic text shadow
    fontFamily: "'Poppins', sans-serif", // Modern font
    color: "#333", // Neutral color
    fontWeight: 500, // Medium font weight
    ...sx, // Allow additional styles via props
  };

  return (
    <Typography variant={variant} sx={defaultStyles}>
      {children}
    </Typography>
  );
};

export default CardTypography;
