import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";

import { fetchRepresentative,editRepresentative } from "../../../redux/slices/universalSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";



const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditRepresentative({ customer_uuid, onBack }) {
  const dispatch = useDispatch();
  const [representative_uuid, setRepresentative_uuid] = useState(null);

  const [formData, setFormData] = useState({
    representative_name: "",
    representative_sname: "",
    representative_email: "",
    representative_cell: "",
    representative_id: "",
  });

  const { customer_representative } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchRepresentative({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  useEffect(() => {
    setRepresentative_uuid(customer_representative.representative_uuid);
    setFormData({
      representative_name: customer_representative.representative_name || "",
      representative_sname: customer_representative.representative_sname || "",
      representative_email: customer_representative.representative_email || "",
      representative_cell: customer_representative.representative_cell || "",
      representative_id: customer_representative.representative_id || "",
    });
  }, [customer_representative]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      const result = await dispatch(editRepresentative({  customer_uuid,representative_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
          Back to Landlord Management
          </Button>
          <CardTypography variant={"h3"}>Edit Representative</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Input 
          label="First Name"
          placeholder="First Name" 
          name="representative_name" 
          value={formData.representative_name} 
          onChange={handleChange} />
          <Input label="Last Name" placeholder="Last Name" name="representative_sname" value={formData.representative_sname} onChange={handleChange} />
          <Input label="Email" placeholder="Email" name="representative_email" value={formData.representative_email} onChange={handleChange} type="email" />
          <Input label="Cell" placeholder="Cell" name="representative_cell" value={formData.representative_cell} onChange={handleChange} />
          <Input label="Id" placeholder="ID" name="representative_id" value={formData.representative_id} onChange={handleChange} />

          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditRepresentative;
