import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";

const BoxButton = ({ label, buttonText, tooltip, disabled, optional_tooltip, icon, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        backgroundColor: "#e0e0e0",
        borderRadius: "15px",
        boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)", // Updated shadow
      }}
    >
      {label && (
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: "10px",
          }}
        >
          {label}
        </Typography>
      )}
      <Tooltip title={tooltip || ""} arrow>
        <Button
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          startIcon={icon}
          sx={{
            backgroundColor: disabled ? "#e0e0e0" : "#f0f0f0",
            color: disabled ? "#aaa" : "#333",
            borderRadius: "12px",
            padding: "10px 20px",
            boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)", // Same shadow as UploadFile
            "&:hover": {
              backgroundColor: disabled ? "#e0e0e0" : "#e5e5e5",
              boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
            },
            fontWeight: "bold",
          }}
        >
          {buttonText}
        </Button>
      </Tooltip>
      {optional_tooltip && (
        <Tooltip title={optional_tooltip} arrow>
          <Typography
            variant="caption"
            sx={{
              color: "#888",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            {optional_tooltip}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default BoxButton;
