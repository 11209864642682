import React from "react";
import { Box } from "@mui/material";

const TableButtonGroup = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center", // Center buttons horizontally
        alignItems: "center", // Align buttons vertically
        gap: 1, // Spacing between buttons
      }}
    >
      {children} {/* Render children passed into the group */}
    </Box>
  );
};

export default TableButtonGroup;
