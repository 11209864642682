// src/components/specific/Verify_registration.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verify_registration_onload } from "../../../redux/slices/admin"; // Ensure the correct path
import { toast } from "react-toastify";
import { Box, Typography, Button, CircularProgress } from "@mui/material";

import CardTypography from "../../../components/Typography/CardTypography";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import Customer from "./components/Customer";
import CustomerBank from "./components/Customer_bank";
import Customer_documents from "./components/Customer_documents";
import Customer_representative from "./components/Customer_representative";
import Complex from "./components/Complex";

const neumorphicBoxStyle = {
  width: "100%",
  maxWidth: "1000px",
  margin: "20px auto",
  padding: "20px",
  background: "#e0e0e0",
  boxShadow: "8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.5)",
  borderRadius: "16px",
};

const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Verify_registration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const customer_uuid = searchParams.get("customer_uuid");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(null);

  useEffect(() => {
    if (!customer_uuid) {
      toast.error("Invalid or missing customer UUID.");
      navigate("/"); // Redirect to admin dashboard or an error page
      return;
    }

    const fetchDetails = async () => {
      try {
        setLoading(true);
        const resultAction = await dispatch(verify_registration_onload(customer_uuid));
        if (verify_registration_onload.fulfilled.match(resultAction)) {
          setCustomerDetails(resultAction.payload);
          setError(null);
        } else {
          setError(resultAction.payload || "Failed to fetch customer details.");
          setCustomerDetails(null);
        }
      } catch (err) {
        console.error("Error fetching customer details:", err);
        setError("An unexpected error occurred while fetching customer details.");
        setCustomerDetails(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [customer_uuid, dispatch, navigate]);

  const handleComplete = () => {
    navigate("/");
  };

  if (loading && !customerDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  if (error && !customerDetails) {
    return (
      <Box textAlign="center" p={4} sx={neumorphicBoxStyle}>
        <CardTypography variant="h5" color="error" gutterBottom>
          {error}
        </CardTypography>
        <Button variant="contained" onClick={() => navigate("/")} sx={buttonStyle}>
          Go to Admin Dashboard
        </Button>
      </Box>
    );
  }

  if (!customerDetails) {
    return null; // Or a placeholder
  }

  const { customer, documents, representative, complexes } = customerDetails;

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <CardTypography variant={"h3"}>Landlord Management</CardTypography>
        </ColumnHeading>
      </Row>

      {/* Individual Components */}
      <Row>
        <ColumnInset xs={12}>
          <Customer initial_customer={customer} customer_uuid={customer_uuid} />
        </ColumnInset>
      </Row>

      <Row>
        <ColumnInset xs={12}>
          <CustomerBank initial_bank={customer} customer_uuid={customer_uuid} />
        </ColumnInset>
      </Row>

      <Row>
        <ColumnInset xs={12}>
          <Customer_documents documents={documents} customer_uuid={customer_uuid} />
        </ColumnInset>
      </Row>

      <Row>
        <ColumnInset xs={12}>{representative && <Customer_representative representative={representative} customer_uuid={customer_uuid} />}</ColumnInset>
      </Row>

      <Row>
        <ColumnInset xs={12}>
          <Complex complexes={complexes} customer_uuid={customer_uuid} />
        </ColumnInset>
      </Row>

      {/* Complete Button */}
      <Box mb={4}>
        <Button variant="contained" onClick={handleComplete} fullWidth sx={buttonStyle}>
          Complete
        </Button>
      </Box>
    </Container>
  );
};

export default Verify_registration;
