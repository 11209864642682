import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, TextField, Button, FormControl, Select, MenuItem, InputLabel, Switch, FormControlLabel } from "@mui/material";

import { fetchCustomer,editCustomer } from "../../../redux/slices/universalSlice";

import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";
import LoaderButton from "../../../components/buttons/LoaderButton";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";


const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

function EditLandlord({ customer_uuid, onBack,heading }) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    customer_name: "",
    customer_sname: "",
    customer_email: "",
    customer_cell: "",
    customer_id: "",
    user_type: "individual",
    company_name: "",
    company_type: "",
    company_vat_number: "",
  });
  const [isCompany, setIsCompany] = useState(false);

  const { customer } = useSelector((state) => state.universalSlice);

  useEffect(() => {
    if (customer_uuid) {
      dispatch(fetchCustomer({ customer_uuid }));
    }
  }, [customer_uuid, dispatch]);

  useEffect(() => {
    setFormData(customer);
    if (customer.user_type == "company") {
      setIsCompany(true);
    }
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUserTypeToggle = () => {
    setIsCompany((prev) => !prev);
    setFormData((prev) => ({
      ...prev,
      user_type: isCompany ? "individual" : "company",
      company_name: "",
      company_type: "",
      company_vat_number: "",
    }));
  };

  const handleSubmit = async () => {
    try {
      const result = await dispatch(editCustomer({  customer_uuid, data: formData })).unwrap();
    } catch (error) {
      console.error("Error updating customer:", error);
    } finally {
      onBack();
    }
  };


  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
          {heading}
          </Button>
          <CardTypography variant={"h3"}>Edit Landlord</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnOutset xs={3}></ColumnOutset>
        <ColumnInset xs={6}>
          <Input label="First Name" placeholder="First Name" name="customer_name" value={formData.customer_name} onChange={handleChange} />
          <Input label="Surname Name" placeholder="Last Name" name="customer_sname" value={formData.customer_sname} onChange={handleChange} />
          <Input label="Email" placeholder="Email" name="customer_email" value={formData.customer_email} onChange={handleChange} type="email" />
          <Input label="Cell" placeholder="Cell" name="customer_cell" value={formData.customer_cell} onChange={handleChange} />
          <Input label="Id" placeholder="ID" name="customer_id" value={formData.customer_id} onChange={handleChange} />

          <FormControlLabel control={<Switch checked={isCompany} onChange={handleUserTypeToggle} />} label="Is this a company?" sx={{ marginTop: "20px" }} />

          {isCompany && (
            <Box>
              <Input label="Company Name" placeholder="Company Name" name="company_name" value={formData.company_name} onChange={handleChange} />
              <Input label="Company Type" placeholder="Company Type" name="company_type" value={formData.company_type} onChange={handleChange} />
              <Input label="VAT Nr" placeholder="VAT Number" name="company_vat_number" value={formData.company_vat_number} onChange={handleChange} />
            </Box>
          )}

<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" }}>
            <LoaderButton buttonText="Save" onClick={handleSubmit} />
            <Box sx={{ marginLeft: "auto" }}>
              <LoaderButton buttonText="Cancel" onClick={onBack} />
            </Box>
          </Box>
        </ColumnInset>
        <ColumnOutset xs={3}></ColumnOutset>
      </Row>
    </Container>
  );
}

export default EditLandlord;
