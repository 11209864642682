// src/components/CustomEmailField.js
import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useScale } from "../../ScaleContext"; // Adjust the path based on your project structure

// Styled TextField with custom styles
const StyledEmailTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "scale",
})(({ theme, scale }) => ({
  // Overall margin between text fields
  margin: scale > 1 ? theme.spacing(1.5, 0) : theme.spacing(2, 0),

  // Label styles
  "& label": {
    color: theme.palette.silverShades.light,
    fontSize: scale > 1 ? "0.8rem" : "1rem", // Adjust font size based on scale
    transform: scale > 1 ? "translate(5px, -18px) scale(1)" : "translate(5px, -20px) scale(1)",
    fontWeight: 200,
  },
  "& label.Mui-focused": {
    color: theme.palette.silverShades.light,
  },
  "& .MuiInputBase-input": {
    color: "rgba(255,255,255,1)", // Text color inside input
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "rgba(0,0,0,0.25)", // Input background color
    "& fieldset": {
      borderColor: theme.palette.blackShades.medium,
      padding: "0 8px", // Reduce fieldset padding
    },
    "&:hover fieldset": {
      borderColor: theme.palette.blackShades.dark, // Border color on hover
      backgroundColor: "rgba(0,0,0,0.45)", // Background color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: theme.palette.blackShades.black, // Border color when focused
    },
    "& .MuiOutlinedInput-input": {
      padding: scale > 1 ? "4px 8px" : "8px 16px", // Adjust padding based on scale
      boxSizing: "border-box",
      lineHeight: 1.2,
      fontSize: scale > 1 ? "0.8rem" : "1rem",
      minHeight: scale > 1 ? "24px" : "32px", // Adjust height if necessary
      color: "rgba(255,255,255,1)", // Text color inside input
    },
  },

  "& .MuiInputAdornment-root": {
    margin: 0,
    "& > *": {
      padding: 0,
    },
  },

  // Autofill styles
  "& input:-webkit-autofill": {
    backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    color: theme.palette.silverShades.light,
    "-webkit-text-fill-color": theme.palette.silverShades.light,
    transition: "background-color 5000s ease-in-out 0s",
  },
}));

const CustomEmailField = (props) => {
  const scale = useScale();
  const theme = useTheme();

  return (
    <StyledEmailTextField
      scale={scale}
      {...props}
      type="text" // Ensures the input is of email type
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <span style={{ color: theme.palette.silverShades.dark }}>
              @meterminds.co.za
            </span>
          </InputAdornment>
        ),
      }}
      inputProps={{
        ...props.inputProps,
        autoComplete: "email", // Sets autocomplete to email
      }}
      variant="outlined" // You can set this as default or pass it via props
      fullWidth // You can set this as default or pass it via props
    />
  );
};

export default CustomEmailField;
