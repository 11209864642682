import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { completeRegistration } from "../../../redux/slices/authSlice"; // Adjust the path as necessary
import { toast } from "react-toastify";
import { Box, Typography, Grid, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import CardTypography from "../../../components/Typography/CardTypography";
import Input from "../../../components/fields/Input";

const neumorphicBoxStyle = {
  maxWidth: 500,
  margin: "50px auto",
  padding: 3,
  borderRadius: "16px",
  background: "#e0e0e0",
  boxShadow: "8px 8px 16px rgba(0, 0, 0, 0.2), -8px -8px 16px rgba(255, 255, 255, 0.5)",
};

const buttonStyle = {
  marginTop: 2,
  background: "#e0e0e0",
  color: "#333",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
  "&:hover": {
    background: "#f0f0f0",
    boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
  },
};

const Complete_registration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const customer_uuid = searchParams.get("customer_uuid");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    bankingDetails: {
      account_number: "",
      account_holder: "",
      bank: "",
      branch_code: "",
    },
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!customer_uuid) {
      toast.error("Invalid or missing customer UUID.");
      navigate("/"); // Redirect to homepage or an error page
    }
  }, [customer_uuid, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (["account_number", "account_holder", "bank", "branch_code"].includes(name)) {
      setFormData((prevState) => ({
        ...prevState,
        bankingDetails: {
          ...prevState.bankingDetails,
          [name]: value,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    if (!formData.username || !formData.password) {
      toast.error("Username and password are required.");
      return;
    }

    if (formData.password.length < 4) {
      toast.error("Password must be at least 4 characters long.");
      return;
    }

    const { account_number, account_holder, bank, branch_code } = formData.bankingDetails;
    if (!account_number || !account_holder || !bank || !branch_code) {
      toast.error("All banking details are required.");
      return;
    }

    setLoading(true);

    try {
      // Dispatch the completeRegistration action
      const resultAction = await dispatch(
        completeRegistration({
          customer_uuid,
          username: formData.username,
          password: formData.password,
          bankingDetails: formData.bankingDetails,
        })
      );

      if (completeRegistration.fulfilled.match(resultAction)) {
        navigate("/customer");
      } else {
        // Error handled in authSlice's rejected case
        // Additional error handling can be done here if necessary
      }
    } catch (error) {
      console.error("Registration error:", error);
      toast.error("An unexpected error occurred during registration.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={neumorphicBoxStyle} className="complete-registration-container">
      <CardTypography variant="h4" align="center" gutterBottom>
        Complete Your Registration
      </CardTypography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Username */}
          <Grid item xs={12}>
            <Input label="Username" name="username" value={formData.username} onChange={handleChange} required placeholder="Enter your desired username" />
          </Grid>

          {/* Password */}
          <Grid item xs={12}>
            <Input label="Password" name="password" type="password" value={formData.password} onChange={handleChange} required minLength={6} placeholder="Enter a secure password" />
          </Grid>

          {/* Banking Details */}
          <Grid item xs={12}>
            <CardTypography variant="h6">Banking Details</CardTypography>
          </Grid>

          {/* Account Number */}
          <Grid item xs={12}>
            <Input label="Account Number" name="account_number" value={formData.bankingDetails.account_number} onChange={handleChange} required placeholder="Enter your account number" />
          </Grid>

          {/* Account Holder */}
          <Grid item xs={12}>
            <Input label="Account Holder" name="account_holder" value={formData.bankingDetails.account_holder} onChange={handleChange} required placeholder="Enter the account holder's name" />
          </Grid>

          {/* Bank */}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="bank-label">Bank</InputLabel>
              <Select labelId="bank-label" name="bank" value={formData.bankingDetails.bank} onChange={handleChange}>
                <MenuItem value="FNB">FNB</MenuItem>
                <MenuItem value="Standard Bank">Standard Bank</MenuItem>
                <MenuItem value="Capitec">Capitec</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Branch Code */}
          <Grid item xs={12}>
            <Input label="Branch Code" name="branch_code" value={formData.bankingDetails.branch_code} onChange={handleChange} required placeholder="Enter your branch code" />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button type="submit" sx={buttonStyle} fullWidth disabled={loading}>
              {loading ? "Completing Registration..." : "Complete Registration"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Complete_registration;
