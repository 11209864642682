// src/Routes.jsx
import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import WebsiteLayout from "./layout/WebsiteLayout";
import WebsitePage from "./pages/website/website/WebsitePage";
import RechargePage from "./pages/website/recharge/RechargePage";
import RegisterPage from "./pages/website/register/RegisterPage";
import GetInTouch from "./pages/website/website/GetInTouch";
import TermsAndConditions from "./components/specific/TermsAndConditions";

import Complete_registration from "./pages/customer/complete_registration/Complete_registration";
import Verify_registration from "./pages/admin/verify_registration/Verify_registration";
//services:
import UtilitySubMetering from "./pages/website/services/UtilitySubMetering/UtilitySubMetering";
import PropertyOwners from "./pages/website/services/UtilitySubMetering/PropertyOwners";
import Complexes from "./pages/website/services/UtilitySubMetering/Complexes";
import Developers from "./pages/website/services/UtilitySubMetering/Developers";

import VendingSolution from "./pages/website/services/VendingSolution/VendingSolution";
import BulkMetering from "./pages/website/services/BulkMetering/BulkMetering";
import Recharge from "./pages/website/services/Recharge/Recharge";

//products:
import Connectivity from "./pages/website/products/Connectivity";
import Suppliers from "./pages/website/products/Suppliers";
import Hexing from "./pages/website/products/Hexing";
import Landis from "./pages/website/products/Landis";
import Laison from "./pages/website/products/Laison";
import Conlog from "./pages/website/products/Conlog";
//debug
import Palette from "./pages/website/website/Palette";

//Landlord/Customer
import CustomerLayout from "./layout/CustomerLayout";
import CustomerPage from "./pages/customer/CustomerPage";
// customer pages
import LandlordManagement from "./pages/customer/landlordManagement/LandlordManagement";
import CustomerReports from "./pages/customer/reports/CustomerReports";

//Admin
import AdminLayout from "./layout/AdminLayout";
import AdminPage from "./pages/admin/AdminPage";
// admin pages
import CustomerManagement from "./pages/admin/customer_management/CustomerManagement";
import ChangeLogManagement from "./pages/admin/changeLogManagement/ChangeLogManagement";
import TariffManagement from "./pages/admin/tariff_management/TariffManagement";
import WebsiteManagement from "./pages/admin/website_management/WebsiteManagement";
import AdminReports from "./pages/admin/reports/AdminReports";


import ProtectedRoute from "./components/common/ProtectedRoute";

import { useSelector } from "react-redux";

function AppRoutes() {
  const auth = useSelector((state) => state.auth);

  return (
    <Routes>
      {/* Public Routes - Website */}
      <Route element={<WebsiteLayout />}>
        <Route path="/palette" element={<Palette />} />
        <Route path="/" element={<WebsitePage />} />
        {/* <Route path="/about" element={<AboutPage />} /> */}
        <Route path="/recharge" element={<RechargePage />} />
        <Route path="/services/utility-sub-metering" element={<UtilitySubMetering />} />
        <Route path="/services/property-owners" element={<PropertyOwners />} />
        <Route path="/services/complexes" element={<Complexes />} />
        <Route path="/services/developers" element={<Developers />} />
        <Route path="/services/vending-solution" element={<VendingSolution />} />
        <Route path="/services/bulk-metering" element={<BulkMetering />} />
        <Route path="/services/recharge" element={<Recharge />} />
        <Route path="/products/suppliers" element={<Suppliers />} />
        <Route path="/supplier/hexing" element={<Hexing />} />
        <Route path="/supplier/conlog" element={<Conlog />} />
        <Route path="/supplier/landis" element={<Landis />} />
        <Route path="/supplier/laison" element={<Laison />} />

        <Route path="/products/connectivity" element={<Connectivity />} />
        <Route path="/contact-us" element={<GetInTouch />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/complete-registration" element={<Complete_registration />} />
        <Route path="/verify-registration" element={<Verify_registration />} /> {/* admin/verify_registration */}
      </Route>

      {/* Customer Routes */}
      <Route element={<ProtectedRoute allowedRoles={["customer"]} />}>
        <Route path="/customer/*" element={<CustomerLayout />}>
          <Route index element={<CustomerPage />} />
          <Route path="landlord-management" element={<LandlordManagement />} />
          <Route path="customer-reports" element={<CustomerReports />} />
        </Route>
      </Route>

      {/* Admin Routes */}
      <Route element={<ProtectedRoute allowedRoles={["admin"]} />}>
        <Route path="/admin/*" element={<AdminLayout />}>
          <Route index element={<AdminPage />} />
          <Route path="customer-management" element={<CustomerManagement />} />
          <Route path="change-log-management" element={<ChangeLogManagement />} />
          <Route path="tariff-management" element={<TariffManagement />} />
          <Route path="website-management" element={<WebsiteManagement />} />
          <Route path="reports" element={<AdminReports />} />
        </Route>
      </Route>

      {/* Redirect after login */}
      <Route path="/login-success" element={auth.role === "admin" ? <Navigate to="/admin" /> : auth.role === "customer" ? <Navigate to="/customer" /> : <Navigate to="/" />} />
    </Routes>
  );
}

export default AppRoutes;
