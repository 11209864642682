import React from "react";
import { Grid, Box } from "@mui/material";

const FilterContainer = ({ xs, children }) => {
  return (
    <Grid
      item
      xs={xs}
      sx={{
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        gap: 1, // Adjust spacing between filters
        padding: "2px",
        height: "100px", // Set a fixed or dynamic height
        overflow: "hidden", // Hide content outside bounds
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          gap: 1, // Adjust spacing between filters
        }}
      >
        {children}
      </Box>
    </Grid>
  );
};

export default FilterContainer;
