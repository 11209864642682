import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CardTypography from "../Typography/CardTypography";

import Chip from "../misc/Chip";
const TenantCard = ({ tenant, meter, callback_edittenant, callback_editmeter = null }) => {
  
  const neumorphicStyles = {
    boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
    borderRadius: "15px",
    background: "#e0e0e0",
    padding: "20px",
    margin: "20px 0",
  };

  const iconStyles = {
    cursor: "pointer",
    boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
    borderRadius: "50%",
    background: "#e0e0e0",
    padding: "5px",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: "6px 6px 12px rgba(0, 0, 0, 0.3), -6px -6px 12px rgba(255, 255, 255, 0.7)",
    },
  };

  const click_edittenant = ({ tenant_uuid }) => {
    callback_edittenant({ tenant_uuid });
  };

  const click_editmeter = ({ meter_uuid ,tenant_uuid}) => {
    if (callback_editmeter != null) {
      callback_editmeter({ meter_uuid,tenant_uuid });
    }
  };

  return (
    <Box sx={{ maxWidth: 600, margin: "auto", ...neumorphicStyles }}>
      {/* Landlord Details */}
      <CardTypography variant="h4">Tenant Details</CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>Tenant: {tenant.tenant_uuid}</CardTypography>
        <CardTypography>
          Name: {tenant.tenant_name} {tenant.tenant_sname}
        </CardTypography>
        <CardTypography>Email: {tenant.tenant_email}</CardTypography>
        <CardTypography>Cell: {tenant.tenant_cell}</CardTypography>
        <CardTypography>ID: {tenant.tenant_id}</CardTypography>
        <CardTypography>Unit: {tenant.tenant_unit}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Chip label={tenant.tenant_verified ? "Verified" : "Unverified"} verified={tenant.tenant_verified} />
        </Box>
      </Box>
      <Button
        onClick={() => click_edittenant({ tenant_uuid: tenant.tenant_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>

      {/* meter Details */}
      <CardTypography variant="h4" sx={{ marginTop: 4 }}>
        Meter Details
      </CardTypography>
      <Divider sx={{ marginY: 1 }} />
      <Box>
        <CardTypography>Meter: {meter.meter_uuid}</CardTypography>
        <CardTypography>Number: {meter.meter_number}</CardTypography>
        <CardTypography>Description: {meter.meter_description}</CardTypography>
        <CardTypography>Utility: {meter.meter_utility}</CardTypography>
        <CardTypography>SGC: {meter.meter_sgc}</CardTypography>
        <CardTypography>Measurement: {meter.meter_measurement}</CardTypography>
        <CardTypography>Tariff: {meter.meter_tariff}</CardTypography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {meter.meter_number ? <CheckCircleIcon color="success" sx={{ marginRight: 1 }} /> : <CancelIcon color="error" sx={{ marginRight: 1 }} />}
          <Chip label={meter.meter_verified ? "Verified" : "Unverified"} verified={meter.meter_verified} />
        </Box>
      </Box>
      <Button
        onClick={() => click_editmeter({ meter_uuid: meter.meter_uuid,tenant_uuid:tenant.tenant_uuid })}
        sx={{
          marginTop: 2,
          background: "#e0e0e0",
          color: "#333",
          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
          "&:hover": {
            background: "#f0f0f0",
            boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
          },
        }}
        variant="contained"
      >
        Edit
      </Button>
    </Box>
  );
};

export default TenantCard;
