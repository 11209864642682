import React, { useState } from "react";
import { Button, CircularProgress } from "@mui/material";

const LoaderButton = ({ buttonText, onClick }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      sx={{
        background: "#e0e0e0",
        color: "#333",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(255, 255, 255, 0.5)",
        "&:hover": {
          background: "#f0f0f0",
          boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.1), inset -2px -2px 5px rgba(255, 255, 255, 0.5)",
        },
        textTransform: "capitalize",
        fontSize: "1rem",
        padding: "0px 12px",
        borderRadius: "8px",
      }}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} sx={{ color: "#333" }} /> : buttonText}
    </Button>
  );
};

export default LoaderButton;
