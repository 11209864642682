import React from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const UploadFile = ({ label, tooltip, callback, disabled, disabled_tooltip,enabled_tooltip }) => {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && callback) {
      callback(file); // Pass the file to the parent-provided callback
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "20px",
        backgroundColor: "#e0e0e0",
        borderRadius: "15px",
        boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
      }}
    >
      <Tooltip title={tooltip} arrow>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: "bold",
            color: "#333",
            marginBottom: "10px",
          }}
        >
          {label}
        </Typography>
      </Tooltip>
      <Button
        variant="contained"
        component="label"
        startIcon={<CloudUploadIcon />}
        disabled={disabled}
        sx={{
          backgroundColor: "#e0e0e0",
          color: disabled ? "#aaa" : "#333",
          borderRadius: "12px",
          padding: "10px 20px",
          boxShadow: disabled
            ? "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff"
            : "4px 4px 8px #bebebe, -4px -4px 8px #ffffff",
          "&:hover": {
            backgroundColor: disabled ? "#e0e0e0" : "#f0f0f0",
            boxShadow: disabled
              ? "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff"
              : "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
          },
        }}
      >
        Upload File
        <input
          type="file"
          accept="*/*"
          hidden
          onChange={handleFileChange}
        />
      </Button>
      {disabled && disabled_tooltip && (
        <Tooltip title={disabled_tooltip} arrow>
          <Typography
            variant="caption"
            sx={{
              color: "#888",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
              {disabled_tooltip}
          </Typography>
        </Tooltip>
      )}
            {!disabled && enabled_tooltip && (
        <Tooltip title={enabled_tooltip} arrow>
          <Typography
            variant="caption"
            sx={{
              color: "#888",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
              {enabled_tooltip}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default UploadFile;
