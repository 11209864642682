import React, { useEffect, useState } from "react";
import { TextField, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import CardTypography from "../Typography/CardTypography";

const MeterForm = ({ tenant, tenants, selectedTenant, setSelectedTenant, processed__list_meter, onSubmit, onCancel }) => {
  const [formValues, setFormValues] = useState({
    meter_number: "",
    list_meter: null,
    meter_sgc: "",
    meter_tariff: "",
  });

  const [manufacturer, setManufacturer] = useState("");
  const manufacturers = Object.keys(processed__list_meter);
  const [availableMeters, setAvailableMeters] = useState([]);
  const [selectedListMeter, setSelectedListMeter] = useState(null);

  useEffect(() => {
    setAvailableMeters(processed__list_meter[manufacturer]);
  }, [manufacturer]);

  useEffect(() => {
    if (selectedListMeter) {
      setFormValues({ ...formValues, list_meter: selectedListMeter });
    }
  }, [selectedListMeter]);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear errors on input change
  };

  const handleValidation = () => {
    const newErrors = {};

    if (!selectedTenant) {
      newErrors.tenant = "Please select a tenant.";
    }

    if (!manufacturer) {
      newErrors.manufacturer = "Please select a manufacturer.";
    }

    if (!formValues.list_meter) {
      newErrors.list_meter = "Please select a meter.";
    }

    if (!formValues.meter_number) {
      newErrors.meter_number = "Meter number is required.";
    }

    if (!formValues.meter_sgc) {
      newErrors.meter_sgc = "Please select a meter SGC.";
    }

    if (!formValues.meter_tariff) {
      newErrors.meter_tariff = "Please select a meter tariff.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    console.log(formValues);
    e.preventDefault();
    if (handleValidation()) {
      onSubmit(formValues);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        margin: "auto",
        padding: 1,
        borderRadius: 3,
        backgroundColor: "#e0e0e0",
        boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
      }}
    >
      <CardTypography variant="h5" sx={{ marginBottom: 2 }}>
        Meter Form
      </CardTypography>

      {!tenant && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
          <Select labelId="tenant-select-label" value={selectedTenant} onChange={(e) => setSelectedTenant(e.target.value)} error={!!errors.tenant} helperText={errors.tenant || " "}>
            {tenants.map((t) => (
              <MenuItem key={t.tenant_uuid} value={t.tenant_uuid}>
                {t.tenant_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="manufacturer-select-label">Select Manufacturer</InputLabel>
        <Select labelId="manufacturer-select-label" value={manufacturer} onChange={(e) => setManufacturer(e.target.value)} error={!!errors.manufacturer} helperText={errors.manufacturer || " "}>
          {manufacturers.map((manu) => (
            <MenuItem key={manu} value={manu}>
              {manu}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {availableMeters && (
        <FormControl fullWidth sx={{ marginBottom: 2 }}>
          <InputLabel id="meter_description">Select Meter Description</InputLabel>
          <Select
            labelId="meter_description"
            id="meter_description_select"
            value={selectedListMeter ? selectedListMeter.meter_code : ""}
            label="Select Meter Description"
            onChange={(e) => {
              const selectedCode = e.target.value;
              const selectedMeter = availableMeters.find((meter) => meter.meter_code === selectedCode);
              setSelectedListMeter(selectedMeter);
            }}
            error={!!errors.list_meter}
            helperText={errors.list_meter || " "}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {availableMeters.map((t) => (
              <MenuItem key={t.list_meter_uuid} value={t.meter_code}>
                {t.meter_code} {t.meter_description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <TextField
        fullWidth
        variant="outlined"
        label="METER NUMBER"
        name="meter_number"
        value={formValues.meter_number}
        onChange={handleChange}
        error={!!errors.meter_number}
        helperText={errors.meter_number || " "}
        sx={{
          marginBottom: 1,
          backgroundColor: "#e0e0e0",
          boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
          borderRadius: 2,
        }}
      />

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="meter-sgc-label">Meter SGC</InputLabel>
        <Select
          labelId="meter-sgc-label"
          name="meter_sgc"
          value={formValues.meter_sgc}
          onChange={handleChange}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
            borderRadius: 2,
          }}
        >
          <MenuItem value="1234">1234</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="meter-measurement-label">Meter Tariff</InputLabel>
        <Select
          labelId="meter-tariff-label"
          name="meter_tariff"
          value={formValues.meter_tariff}
          onChange={handleChange}
          error={!!errors.meter_tariff}
          helperText={errors.meter_tariff || " "}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "inset 3px 3px 6px rgba(0, 0, 0, 0.2), inset -3px -3px 6px rgba(255, 255, 255, 0.5)",
            borderRadius: 2,
          }}
        >
          <MenuItem value="1">1</MenuItem>
        </Select>
      </FormControl>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 3,
        }}
      >
        <Button
          onClick={onCancel}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
            "&:hover": {
              backgroundColor: "#e5e5e5",
              boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          sx={{
            backgroundColor: "#e0e0e0",
            boxShadow: "7px 7px 15px rgba(0, 0, 0, 0.2), -7px -7px 15px rgba(255, 255, 255, 0.5)",
            "&:hover": {
              backgroundColor: "#e5e5e5",
              boxShadow: "inset 2px 2px 5px #bebebe, inset -2px -2px 5px #ffffff",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default MeterForm;
