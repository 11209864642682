//landlord_management/view/capture/captureTenant.jsx
import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";

import Container from "../../../components/Grid/Container";
import Row from "../../../components/Grid/Row";
import Column from "../../../components/Grid/Column";

import ColumnInset from "../../../components/Grid/ColumnInset";
import ColumnOutset from "../../../components/Grid/ColumnOutset";
import ColumnHeading from "../../../components/Grid/ColumnHeading";

import CardTypography from "../../../components/Typography/CardTypography";

import TenantForm from "../../../components/forms/TenantForm";

import { useSelector, useDispatch } from "react-redux";
import { captureTenant } from "../../../redux/slices/complexManagementSlice";

function CaptureTenant({ complex, onBack }) {
  const dispatch = useDispatch();

  const click__submit_tenant = (formData) => {
    dispatch(captureTenant({ tenant: formData, complex }))
      .unwrap()
      .then(() => {
        onBack(); // Navigate back on success
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const click__cancel_tenant = () => {
    console.log("Cancel clicked");
    onBack();
  };

  useEffect(() => {
    console.log("CaptureTenant mounted");
    return () => {
      console.log("CaptureTenant unmounted");
    };
  }, []);

  return (
    <Container>
      <Row>
        <ColumnHeading xs={12}>
          <Button onClick={onBack} variant="contained" color="primary" sx={{ marginBottom: 2 }}>
            Back to Complex Management
          </Button>
          <CardTypography variant={"h3"}>Capture Tenant</CardTypography>
        </ColumnHeading>
      </Row>

      <Row>
        <ColumnInset xs={12} alignItems="center">
          <TenantForm onSubmit={click__submit_tenant} onCancel={click__cancel_tenant} />
        </ColumnInset>
      </Row>
    </Container>
  );
}

export default CaptureTenant;
